import { base64 } from "@firebase/util";
import React, { ReactElement } from "react";
import { dominioList, dominio, ModalitaServizio, MappingDettaglio, MappingCensimento } from "./interface";

export const getOptionsFromDomain = (domain?: dominio[]) => {
  if (domain === null || domain === undefined)
    return [];

  let options: any[] = [];
  domain.forEach((e) => {
    options.push({ value: e.codice, label: e.descrizione });
  });

  return options;
};

export const getOptionsFromDomainList = (domain: Array<number>) => {
  let options: ReactElement[] = [];
  domain.forEach((element, i) => options.push(<option key={i} value={element}>{element}</option>));
  return options;
};

export const getAPIDefaultListState = () => {
  return { data: [], metadata: {}, pagination: { count: 0, page: 0, per_page: 0 } };
}

export const getAPIMappingObjectState = () => {
  return { data: { campiEditabili: [] as string[], campiVisibili: [] as string[] }, metadata: {} };
}

export const getAPIDefaultState = () => {
  return {
    data: {
      codTitolo: '', desProdotto: '', ISIN: '', codTipoStrumento: '',
      codSottotipoStrumento: '', desEmittente: '', codDivisaEmissione: '',
      ramoPolizza: '', flagAcquistabile: '', flagVendibile: '', flagBlocco: '',
      dataApertura: '', dataChiusura: '', flagMifid: '', flagIlliquido: '',
      flagCedola: '', flagPic: '', flagPac: '', flagMultipac: '', importoLottoMinAcquisto: '',
      importoLottoMinSuccessivo: '', quantitaLottoMinAcquisto: '', quantitaLottoMinSuccessivo: '',
      flagLottoMultiplo: '', desPaeseEmissione: '', flagPIR: '', flagPirAlternative: '',
      flagSwitch: '', flagPRIIP: '', flagKID: '', classeConcentrazione: '', codClasseFondo: '',
      flagComplesso: '', codBisogno: '', desBisogno: '', rating: '', priority: '',
      prezzoEmissione: '', flagKIID: '', dataScadenza: '', codOperazioniAssicurative: '',
      flagFEQ: '', flagPrecompila: '', codEmittente: '', codTipoEmittente: '', desTipoEmittente: '',
      dataEmissione: '', codDivisaTrattazione: '', qualitaProdotto: '', flagLoad: '', flagPinl: '',
      codTipoBanca: '', desTipoBanca: '', codSottotipoBanca: '', desSottotipoBanca: '',
      flagQualificatoUcits: '', flagQualificatoFia: '', flagEltif: '',
      flagHedge: '', catalogoCommerciale: [] as ModalitaServizio[]
    } as any,
    metadata: {}
  };
}

export const getFormikValues = (values: any) => {
  let res: any = {};
  for (let [key, value] of Object.entries(values)) {
    if (['', undefined].indexOf(value as any) === -1) {
      res[key] = value;
    }
  }
  return res;
}

export const getFormikValuesPut = (values: any) => {
  let res: any = {};
  for (let [key, value] of Object.entries(values)) {
    res[key] = value;
  }
  return res;
}

export function dateFormatterForAPI(date?: Date) {
  if(date === undefined || date === null)
    return null;

  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

export function dateFormatterToShow(date?:string){
  if(date === undefined || date == null)
    return '';

  //perchè è formato yyyy-mm-dd
  const dateArray = date.split('-');

  return [dateArray[2], dateArray[1], dateArray[0]].join('/');
}

export const getElement = (listaDomain: dominioList[], nomeDominio?: string, dependency?: string) => {
  if (nomeDominio === undefined || nomeDominio === null) {
    return [];
  }

  if (!dependency) {
    return listaDomain.find((x: dominioList) => x.nomeDominio === nomeDominio)?.dettagli;
  } else {
    if (dependency.includes('null')) {
      return listaDomain.find((x: dominioList) => x.nomeDominio === nomeDominio)?.dettagli
        ?.filter((dominio) => !dominio.codice.includes('|'));
    } else {
      return listaDomain.find((x: dominioList) => x.nomeDominio === nomeDominio)?.dettagli
        ?.filter((dominio) => dominio.codice.includes(dependency));
    }
  }
}

export const isUserAdmin = (user: any) => {
  return user?.email! === 'admin_replytech@test.azimut.it';
}

export const getUserInfo = (token: string) => {
  const splittedToken = token.split('.');
  return JSON.parse(base64.decodeString(splittedToken[1], false).toString());
}

export const isUserEditor = (token: string): boolean => {
  const userInfo = getUserInfo(token);
  if (!userInfo) {
    return false;
  }
  const roles = userInfo?.firebase?.sign_in_attributes?.RUOLI as string;
  return roles.includes('EDITOR_PRODOTTI');
}

export const getMappingStructure = (mapping: { campiEditabili: string[], campiVisibili: string[] }) => {
  let structure: MappingDettaglio = {};
  mapping.campiVisibili.forEach((field) => structure[field] = { isEditable: false, isVisibile: true });
  mapping.campiEditabili.forEach((field) => structure[field].isEditable = true);
  return structure;
}

export const getMappingStructureCensimento = (mapping: { campiEditabili: any[], campiVisibili: any[] }) => {
  let structure : MappingCensimento = {};
  
  mapping.campiVisibili.forEach((field: { jsonObject:string, name:string }) => {
    if(structure[field.jsonObject] === undefined)
      structure[field.jsonObject] = {}
      
    structure[field.jsonObject][field.name] = { isEditable: false, isVisibile: true, isRequired: false };
  });

  mapping.campiEditabili.forEach((field: { jsonObject:string, name:string, isRequired:boolean }) => {
      structure[field.jsonObject][field.name] = { isEditable: true, isVisibile: true, isRequired: field.isRequired };
  });

  return structure;
}

const getObjectWithNulls = (obj: any) => {
  return Object.keys(obj).reduce(
    (acc, key) => {
      if(obj[key] === '')
        return {...acc, [key]: null}
      return {...acc, [key]: obj[key]}
    }, {} as any 
  )
}

export const getBodyObjectWithNulls = (
    values: { titolo: any, fondo: any, prezzo: any, gruppoSwitch: any, raccordoTitoli: any, 
      polizza: any, catalogoCommerciale: any[], tipoProdotto: string, flagProdottoPadre: boolean }
  ) => {
  return {
    titolo: getObjectWithNulls(values.titolo), 
    fondo: getObjectWithNulls(values.fondo),
    prezzo: getObjectWithNulls(values.prezzo),
    gruppoSwitch: getObjectWithNulls(values.gruppoSwitch), 
    raccordoTitoli: getObjectWithNulls(values.raccordoTitoli),
    polizza: getObjectWithNulls(values.polizza),
    catalogoCommerciale: values.catalogoCommerciale, 
    tipoProdotto: values.tipoProdotto,
    flagProdottoPadre: values.flagProdottoPadre
  };
}

export const noNullNoUndefinedNoEmptyString = (x:any) => {
  return x !== undefined && x !== null && x !== '';
}