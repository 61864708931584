import axios from "axios";

export async function inserisciProdotto(params: any) {
    return await axios
      .post(`/catalogoProdottiBe/v2/censimentoProdotto`, { ...params })
      .then((response) => response);
}

export async function mappingCensimentoProdottoAPI() {
    return await axios
      .get(`/catalogoProdottiBe/v2/mappingCensimentoProdotto`)
      .then((response) => response);
}