import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { tipoProdottoFiltroRicercaLinee } from "../../../shared/domains";
import { SelectField } from "../../../shared/FormikFields/SelectField";
import { getFormikValues, getOptionsFromDomain, noNullNoUndefinedNoEmptyString } from "../../../shared/functions";
import { countListaLinee, ricercaLinee, selectListaLinee } from "./RicercaLineeSlice";
import { Button, Table } from "react-bootstrap";
import { Paginator } from "../../../paginator/Paginator";

interface RicercaLineeProps{
    setFieldValueParent:any,
    error:any
}

export function RicercaLinee(props : RicercaLineeProps){
    const initialValues = {
        codTitolo: '',
        desTitolo: '',
        tipoProdotto: 'GP'
    };
    const setFieldValueParent = props.setFieldValueParent;

    const dispatch = useAppDispatch();
    const initialPerPage = 3;
    const listaLinee = useAppSelector(selectListaLinee);
    const countPagination = useAppSelector(countListaLinee);
    const [page, setPage] = useState(0);
    const [indexSelected, setIndexSelected] = useState(-1);
    const [formikValues, setFormikValues] = useState({} as any);
    const [pressed, setPressed] = useState(false);

    const onChangePage = (i:number) => setPage(i);

    const onRowSelection = (item:any, index:number) => {
        if(index !== indexSelected){
            setIndexSelected(index);
            setFieldValueParent('codTitolo', item.codTitolo);
        } else {
            setIndexSelected(-1)
            setFieldValueParent('codTitolo', '');
        }            
    }

    useEffect(() => {
        if(pressed){
            setIndexSelected(-1);
            setFieldValueParent('codTitolo', '');
            dispatch(ricercaLinee({...getFormikValues(formikValues), page: page, perPage: initialPerPage}))
        }
    }, [dispatch, formikValues, page, initialPerPage, pressed, setFieldValueParent])

    return(
        <>
            <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues = {initialValues}
                validate={(values) => {
                    let errors = {} as any;

                    if(!noNullNoUndefinedNoEmptyString(values.codTitolo) && !noNullNoUndefinedNoEmptyString(values.desTitolo))
                        errors['codTitolo'] = 'Valorizzare almeno un campo tra Codice Titolo e Descrizione Titolo';

                    if(!noNullNoUndefinedNoEmptyString(values.tipoProdotto))
                        errors['tipoProdotto'] = true;
                    
                    return errors;
                }}
                onSubmit={(values) => {
                    setPressed(true);
                    setFormikValues(values);
                    setIndexSelected(-1);
                    setPage(0);
                }}
            >
                {({errors}) => (
                    <Form>
                    <div className="d-flex flex-column bg-light p-1">
                        <div className="d-flex flex-fill flex-wrap">
                            <div className="m-2 flex-fill col-3">
                                <Field type="text" name="codTitolo" placeholder="Codice Titolo" className="form-control" />    
                            </div>
                            <div className="m-2 flex-fill col-4">
                                <Field type="text" name="desTitolo" placeholder="Descrizione Titolo" className="form-control"/>
                            </div>
                            <div className="m-2 flex-fill col-3">
                                <Field type="text" name="tipoProdotto" placeholder="Tipologia Titolo" isClearableFlag={false}
                                    component={SelectField} options={getOptionsFromDomain(tipoProdottoFiltroRicercaLinee)}/>
                            </div>
                            <div className="m-2 flex-fill col-1 text-center">
                                <Button variant="primary" className="btn-ricerca" type="submit" >Cerca</Button>
                            </div>
                        </div>  
                        {
                            (noNullNoUndefinedNoEmptyString(props.error) || noNullNoUndefinedNoEmptyString(errors.codTitolo)) && 
                            <>
                                <div className="d-flex flex-fill flex-wrap errors px-2 mx-2 mb-1">
                                    {errors.codTitolo}
                                </div>
                                <div className="d-flex flex-fill flex-wrap errors px-2 mx-2 mb-1">
                                    {props.error}
                                </div>
                            </>
                        }
                        
                    </div>
                    
                    </Form>
                )}
            </Formik>
            {
                listaLinee !== null && listaLinee.length > 0 &&
                <>
                    <Table hover className="table-fixed" responsive>
                        <thead>
                            <tr>
                                <th>Codice Titolo</th>
                                <th>Descrizione Titolo</th>
                            </tr>
                        </thead>
                        <tbody>
                        {listaLinee.map((item: any, index: number) =>
                            <tr key={index} onClick={() => onRowSelection(item, index)} className={`${indexSelected === index ? 'clickable selected' : 'clickable'}`}>
                            <td>{item.codTitolo}</td>
                            <td>{item.desTitolo}</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                    <Paginator totalItems={countPagination} page={page} perPage={initialPerPage} callback={onChangePage} callbackPerPage={() => {}}
                        hidePerPage={true} perPageDomain={[]}/>
                </>
            }
        </>
    )
}