import React from 'react'
import Select from 'react-select'

export const SelectBooleanField = ({ placeholder, options, field, form, disabled, className, isClearable, handleChange}: any) => (
  <Select
    key={`key_${field.value}`}
    options={options}
    name={field.name}
    placeholder={placeholder}
    value={options ? options.find((option: any) => option.value === field.value) : ''}
    onChange={handleChange !== undefined ? (option:any) => handleChange(option, form.values, form.setFieldValue) : 
      (option: any) => form.setFieldValue(field.name, option?.value !== undefined ? option.value : null)}
    className={className}

    isDisabled={disabled}
    isClearable={isClearable !== undefined ? isClearable : true}
  />
)