import React from 'react'
import { createFilter } from 'react-select'
import AsyncSelect from 'react-select/async'

export function AsyncSelectField({ placeholder, options, field, form, disabled, className }: any) {

  const loadOptions = (searchValue: any, callback: any) => {
    setTimeout(() => {
      const filteredOptions = options.filter(
        (option: any) => option.label.toLowerCase().includes(searchValue.toLowerCase())
      )
      callback(filteredOptions)
    }, 2000)
  }

  return (
    <AsyncSelect
      key={`key_${field.value}`}
      options={options}
      loadOptions={loadOptions}
      noOptionsMessage={() => null}
      name={field.name}
      placeholder={placeholder}
      isClearable
      filterOption={createFilter({ ignoreAccents: false })}
      value={options ? options.find((option: any) => option.value === field.value) : ''}
      onChange={(option: any) => form.setFieldValue(field.name, option?.value)}
      onBlur={field.onBlur}
      isDisabled={disabled}
      className={className}
    />
  )
}