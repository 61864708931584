import { Field, Form, Formik } from "formik";
import React from "react";
import { Button } from "react-bootstrap";
import { useAppDispatch } from "../../../app/hooks";
import { DateField } from "../../shared/FormikFields/DateField";
import { getFormikValuesPut, noNullNoUndefinedNoEmptyString } from "../../shared/functions";
import { censimentoLinee } from "./CensimentoLineeSlice";
import { RicercaLinee } from "./ricercaLinee/RicercaLinee";

export function CensimentoLinee(){
    const intialValues = {
        codCliente : '',
        codAgente: '',
        codTitolo: '',
        dataInizio: '',
        dataFine: '',
        percentualeComplessita: null
    }
    const dispatch = useAppDispatch();

    return(
        <>
            <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues = {intialValues}
                validate={(values) => {
                    let errors = {} as any;

                    if(!noNullNoUndefinedNoEmptyString(values.codCliente))
                        errors['codCliente'] = true;

                    if(!noNullNoUndefinedNoEmptyString(values.codAgente))
                        errors['codAgente'] = true;
                    
                    if(!noNullNoUndefinedNoEmptyString(values.codTitolo))
                        errors['codTitolo'] = 'Selezionare linea da censire';
                    
                    if(!noNullNoUndefinedNoEmptyString(values.dataInizio))
                        errors['dataInizio'] = true;

                    if(!noNullNoUndefinedNoEmptyString(values.percentualeComplessita))
                        errors['percentualeComplessita'] = true;

                    if(noNullNoUndefinedNoEmptyString(values.dataInizio) && noNullNoUndefinedNoEmptyString(values.dataFine) &&
                        new Date(values.dataInizio) > new Date(values.dataFine))
                        errors['dataFine'] = 'Data Inizio deve precedere Data Fine';
                    
                    if(noNullNoUndefinedNoEmptyString(values.percentualeComplessita) && 
                        (values?.percentualeComplessita! < 0 || values?.percentualeComplessita! > 100))
                        errors['percentualeComplessita'] = 'Percentuale complessità deve essere nel range [0, 100]';

                    console.log("values", values)
                    console.log("errors", errors);
                    return errors;
                }}
                onSubmit={(values) => {
                    dispatch(censimentoLinee(getFormikValuesPut(values)))
                }}
            >
                {({errors, setFieldValue, submitForm}) => (
                    <>
                        <Form>
                            <div className="d-flex flex-column bg-light p-1 rounded mb-3">
                                <div className="d-flex flex-fill flex-wrap">
                                    <div className="m-2 flex-fill">
                                        <Field type="text" name="codCliente" placeholder="Codice Cliente" className={`${errors.codCliente ? 'border border-danger rounded form-control' : 'form-control'}`}/>    
                                    </div>
                                    <div className="m-2 flex-fill">
                                        <Field type="text" name="codAgente" placeholder="Codice Agente" className={`${errors.codAgente ? 'border border-danger rounded form-control' : 'form-control'}`}/>
                                    </div>
                                </div>
                                <div className="d-flex flex-fill flex-wrap">
                                    <div className="m-2 flex-fill">
                                        <Field type="text" name="dataInizio" placeholder="Data Inizio" className={`${errors.dataInizio ? 'border border-danger rounded form-control' : 'form-control'}`}
                                            component={DateField}/>    
                                    </div>
                                    <div className="m-2 flex-fill">
                                        <Field type="text" name="dataFine" placeholder="Data Fine" className="form-control"
                                            component={DateField}/>
                                    </div>
                                    <div className="m-2 flex-fill">
                                        <Field type="number" name="percentualeComplessita" placeholder="Percentuale Complessità" className={`${errors.percentualeComplessita ? 'border border-danger rounded form-control' : 'form-control'}`}/>
                                    </div>
                                </div>
                                {
                                    (noNullNoUndefinedNoEmptyString(errors.dataFine) || noNullNoUndefinedNoEmptyString(errors.percentualeComplessita)) && 
                                    <>
                                        <div className="d-flex flex-fill flex-wrap errors px-2 mx-2 mb-1">
                                            {errors.dataFine}
                                        </div>
                                        <div className="d-flex flex-fill flex-wrap errors px-2 mx-2 mb-1">
                                            {errors.percentualeComplessita}
                                        </div>
                                    </>
                                }
                            </div>
                        </Form>

                        <RicercaLinee setFieldValueParent={setFieldValue} error={errors.codTitolo}/>

                        <div className="text-center mt-3">
                            <Button variant="primary" className="btn-ricerca" onClick={submitForm} >Censimento</Button>
                        </div>
                    </>
                )}
            </Formik>
        </>
    )
}