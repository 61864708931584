import {Field, Form, Formik, FormikValues} from "formik";
import React, {ReactNode, useContext, useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {getDettaglioProdotto, selectDettaglioProdotto} from "./DettaglioProdottoSlice";
import {DateField} from "../../shared/FormikFields/DateField";
import {UserContext} from "../../../App";
import {isUserEditor} from "../../shared/functions";
import {selectListaProdotti} from "../lista/ListaProdottiSlice";

interface DettaglioProps {
    codTitolo : string,
    indexCatalogoCommerciale : number,
    callback: (formData: any) => void;
    closeModal: () => void
}

export function DettaglioCatalogoCommerciale(props: DettaglioProps){
    const [editMode, setEditMode] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(false);
    const dispatch = useAppDispatch();
    const dettaglioProdotto = useAppSelector(selectDettaglioProdotto);
    const listaProdotti = useAppSelector(selectListaProdotti);
    const user = useContext(UserContext);
    const detailFormFields = [
        [{name: 'flagAttivo', label: '', type: 'checkbox'}, {name: 'dataInizio', label: '', type: 'date'}, {name: 'dataFine', label: '', type: 'date'}, {name: 'flagAttivabile', label: '', type: 'checkbox'}, {name: 'codModalitaServizio', label: '', type: 'text'}, {name: 'desModalitaServizio', label: '', type: 'text'},],
        [{name: 'flagAttivo', label: '', type: 'checkbox'}, {name: 'dataInizio', label: '', type: 'date'}, {name: 'dataFine', label: '', type: 'date'}, {name: 'flagAttivabile', label: '', type: 'checkbox'}, {name: 'codModalitaServizio', label: '', type: 'text'}, {name: 'desModalitaServizio', label: '', type: 'text'},],
        [{name: 'flagAttivo', label: '', type: 'checkbox'}, {name: 'dataInizio', label: '', type: 'date'}, {name: 'dataFine', label: '', type: 'date'}, {name: 'flagAttivabile', label: '', type: 'checkbox'}, {name: 'codModalitaServizio', label: '', type: 'text'}, {name: 'desModalitaServizio', label: '', type: 'text'},],
        [{name: 'flagAttivo', label: '', type: 'checkbox'}, {name: 'dataInizio', label: '', type: 'date'}, {name: 'dataFine', label: '', type: 'date'}, {name: 'flagAttivabile', label: '', type: 'checkbox'}, {name: 'codModalitaServizio', label: '', type: 'text'}, {name: 'desModalitaServizio', label: '', type: 'text'},],
        [{name: 'flagAttivo', label: '', type: 'checkbox'}, {name: 'dataInizio', label: '', type: 'date'}, {name: 'dataFine', label: '', type: 'date'}, {name: 'flagAttivabile', label: '', type: 'checkbox'}, {name: 'codModalitaServizio', label: '', type: 'text'}, {name: 'desModalitaServizio', label: '', type: 'text'},]
    ];

    const getInitialValues = () => {
        let initialValuesArr : any[] = [];
        detailFormFields.forEach(x => {
            let obj = {} as any;
            x.forEach(e => {
                if(e.type === 'checkbox')
                    obj = {...obj, [e.name]: false};
                if(e.type === 'date')
                    obj = {...obj, [e.name]: undefined};
                if(e.type === 'text')
                    obj = {...obj, [e.name]: ""};
            })
            initialValuesArr.push(obj);
        })
        return initialValuesArr;
    }

    const initialValues = getInitialValues();

    useEffect(() => {
        if (user?.uid) {
            user.getIdToken(true).then((token: string) => setUserHasPermission(isUserEditor(token)));
        }
    });
    
    useEffect(() => {
        dispatch(getDettaglioProdotto(props.codTitolo));
    }, [dispatch, props.codTitolo]);

    const renderOneModServ = (x:any, i:number, values:any) => {
        return (
            <div key={`key_[${i}]`} className="d-flex flex-row justify-content-between">
                <div className="m-2">
                    <Field id={`catalogoCommerciale[${i}].flagAttivo`} type="checkbox" name={`catalogoCommerciale[${i}].flagAttivo`}
                           disabled={!editMode || (editMode && !values.catalogoCommerciale[i].flagAttivabile)}/>
                    {values.catalogoCommerciale[i].desModalitaServizio}
                </div>
                <div className="d-flex justify-content-end">
                    <div className="mx-3">
                        <Field id={`catalogoCommerciale[${i}].dataInizio`} component={DateField} name={`catalogoCommerciale[${i}].dataInizio`}
                               placeholder=" N / A " disabled={!editMode || (editMode && !values.catalogoCommerciale[i].flagAttivo)}/>
                    </div>
                    <div>
                        <Field id={`catalogoCommerciale[${i}].dataFine`} component={DateField} name={`catalogoCommerciale[${i}].dataFine`}
                               placeholder=" N / A " disabled={!editMode || (editMode && !values.catalogoCommerciale[i].flagAttivo)}/>
                    </div>
                </div>
            </div>
        )
    }

    const renderDetail = (values:any) => {
        let detail: ReactNode[] = [];
        detailFormFields.forEach((x, i) => {
            x.forEach((field) => initialValues[i][field.name] = dettaglioProdotto.catalogoCommerciale[i][field.name]);
            detail.push(renderOneModServ(x, i, values));
        })
        return detail;
    }

    const handleAnnulla = (resetForm:any) => {
        setEditMode(false);
        resetForm();
    }

    return(
        <>{
            dettaglioProdotto != null && dettaglioProdotto.catalogoCommerciale != null && dettaglioProdotto.catalogoCommerciale.length > 0 &&
            <Formik
                enableReinitialize={true} 
                initialValues={{catalogoCommerciale: initialValues}}
                validate={(values) => {
                    const errors = {} as any;
                    values.catalogoCommerciale.forEach(
                        (catComm:any, i:number) => {
                            if(catComm.flagAttivo && catComm.dataInizio === null)
                                errors.dataInizio = 'Data inizio obbligatoria per l\'aggiunta di una modalità di servizio';

                            //chek su quello presente nello store ricevuto da API
                            let ccTempFromAPI = dettaglioProdotto.catalogoCommerciale.find((e:any) => e.codModalitaServizio === catComm.codModalitaServizio);
                            if(ccTempFromAPI !== null && ccTempFromAPI !== undefined){
                                if(ccTempFromAPI.flagAttivo && !catComm.flagAttivo && (catComm.dataFine === null || catComm.dataFine === undefined))
                                    errors.dataFine = 'Data fine obbligatoria per l\'eliminazione di una modalità di servizio';
                            }
                        }
                    )

                    return errors;
                }}
                onSubmit={(values, actions) => {

                    //nell'aggiorna catalogo commerciale passo le info a livello di macroprodotto (contenute sia nel dettaglio che nella lista alla posizione 'indexCatalogoCommerciale')
                    let params = {  desProdotto: dettaglioProdotto['desProdotto'], codSottotipoStrumento: dettaglioProdotto['codSottotipoStrumento'],
                            codTipoStrumento: dettaglioProdotto['codTipoStrumento'], data : values.catalogoCommerciale } as any;

                    if(dettaglioProdotto['ISIN'] !== null)
                        params['ISIN'] = dettaglioProdotto['ISIN'];
                    if(listaProdotti[props.indexCatalogoCommerciale]['desEmittente'] !== null)
                        params['desEmittente'] = listaProdotti[props.indexCatalogoCommerciale]['desEmittente'];

                    actions.setSubmitting(false);
                    props.callback(params);
                }}>
                {({values, errors, resetForm}: {values:FormikValues, errors:any, resetForm:any} ) => (
                    <Form>
                        <div className="text-end m-2">
                            {!editMode && userHasPermission &&
                                <Button variant="primary" type="button" onClick={() => setEditMode(true)}>
                                    Modifica
                                </Button>}
                            {editMode &&
                                <div>
                                    <Button className="me-3" variant="secondary" onClick={() => handleAnnulla(resetForm)}>
                                        Annulla
                                    </Button>
                                    <Button variant="danger" type="submit">
                                        Conferma
                                    </Button>
                                </div>}
                        </div>
                        <div className="d-flex flex-column flex-wrapper">
                            <div id="title" className="d-flex flex-row justify-content-between p-2 cc-title">
                                <div className="m-2">MODALITA SERVIZIO </div>
                                <div className="d-flex justify-content-end">
                                    <div className="mx-4">DATA INIZIO</div>
                                    <div className="mx-5">DATA FINE</div>
                                </div>
                            </div>
                        </div>
                        {renderDetail(values)}
                        {errors.dataInizio && <p className="errors m-2">{errors.dataInizio}</p>}
                        {errors.dataFine && <p className="errors m-2">{errors.dataFine}</p>}
                    </Form>
                )}
            </Formik>
        }
        </>
    );
}
