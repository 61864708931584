declare global {
  interface Window {
    REACT_APP_API_KEY: string;
    REACT_APP_AUTH_DOMAIN: string;
    REACT_APP_BASE_URL: string;
    REACT_APP_TENANT_ID: string;
    REACT_APP_ENABLE_CENSIMENTO_PRODOTTO: boolean;
  }
}
const REACT_APP_API_KEY = window.REACT_APP_API_KEY || '';
const REACT_APP_AUTH_DOMAIN = window.REACT_APP_AUTH_DOMAIN || '';
const REACT_APP_BASE_URL = window.REACT_APP_BASE_URL || '';
const REACT_APP_TENANT_ID = window.REACT_APP_TENANT_ID || '';
const REACT_APP_ENABLE_CENSIMENTO_PRODOTTO = window.REACT_APP_ENABLE_CENSIMENTO_PRODOTTO;

const ITEMS_PER_PAGE = 10;
export {
  REACT_APP_API_KEY,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_BASE_URL,
  REACT_APP_TENANT_ID,
  ITEMS_PER_PAGE,
  REACT_APP_ENABLE_CENSIMENTO_PRODOTTO
};
