import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../../app/store";
import {getAPIDefaultState, getAPIMappingObjectState} from "../../shared/functions";
import {Status} from "../../shared/interface";
import {
  aggiornaCatalogoCommerciale,
  aggiornaDettaglioProdotto,
  mappingDettaglioCampi,
  recuperaDettaglioProdotto
} from "./DettaglioProdottoAPI";

const initialState = {
  dettaglioProdottoStatus: Status.idle,
  dettaglioProdotto: getAPIDefaultState(),
  putDettaglioProdottoStatus: Status.idle,
  putCatalogoCommercialeStatus: Status.idle,
  mappingDettaglioCampi: getAPIMappingObjectState(),
  getMappingDettaglioCampiStatus: Status.idle,
}

export const getDettaglioProdotto = createAsyncThunk(
  'catalogoProdotti/dettaglioProdotto',
  async (codTitolo: string) => {
    const response = await recuperaDettaglioProdotto(codTitolo);
    return response.data;
  }
);

export const putDettaglioProdotto = createAsyncThunk(
  'catalogoProdotti/putDettaglioProdotto',
  async (params: any) => {
    const response = await aggiornaDettaglioProdotto(params);
    return response.data;
  }
);

export const putCatalogoCommerciale = createAsyncThunk(
  'catalogoProdotti/putCatalogoCommerciale',
  async (params:any) => {
    const response = await aggiornaCatalogoCommerciale(params);
    return response.data;
  }
)

export const getMappingDettaglioCampi = createAsyncThunk(
  'catalogoProdotti/getMappingDettaglioCampi',
  async (params: any) => {
    const response = await mappingDettaglioCampi(params);
    return response.data;
  }
);

export const dettaglioProdottoSlice = createSlice({
  name: 'dettaglioProdotto',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDettaglioProdotto.pending, (state) => {
        state.dettaglioProdottoStatus = Status.start;
      })
      .addCase(getDettaglioProdotto.fulfilled, (state, action) => {
        state.dettaglioProdottoStatus = Status.success;
        state.dettaglioProdotto = action.payload;
      })
      .addCase(getDettaglioProdotto.rejected, (state) => {
        state.dettaglioProdottoStatus = Status.failed;
      })
      .addCase(putDettaglioProdotto.pending, (state) => {
        state.putDettaglioProdottoStatus = Status.start;
      })
      .addCase(putDettaglioProdotto.fulfilled, (state, action) => {
        state.putDettaglioProdottoStatus = Status.success;
        state.dettaglioProdotto = action.payload;
      })
      .addCase(putDettaglioProdotto.rejected, (state) => {
        state.putDettaglioProdottoStatus = Status.failed;
      })
      .addCase(putCatalogoCommerciale.pending, (state) => {
        state.putCatalogoCommercialeStatus = Status.start
      })
      .addCase(putCatalogoCommerciale.fulfilled, (state) => {
        state.putCatalogoCommercialeStatus = Status.success;
      })
      .addCase(putCatalogoCommerciale.rejected, (state) => {
        state.putCatalogoCommercialeStatus = Status.failed;
      })
      .addCase(getMappingDettaglioCampi.pending, (state) => {
        state.getMappingDettaglioCampiStatus = Status.start;
      })
      .addCase(getMappingDettaglioCampi.fulfilled, (state, action) => {
        state.getMappingDettaglioCampiStatus = Status.success;
        state.mappingDettaglioCampi = action.payload;
      })
      .addCase(getMappingDettaglioCampi.rejected, (state) => {
        state.getMappingDettaglioCampiStatus = Status.failed;
      })
  },
});

export const selectDettaglioProdotto = (state: RootState) => state.dettaglioProdottoStore.dettaglioProdotto.data;
export const selectAggiornaDettaglioProdottoStatus = (state: RootState) => state.dettaglioProdottoStore.putDettaglioProdottoStatus;
export const selectMappingDettaglioCampi = (state: RootState) => state.dettaglioProdottoStore.mappingDettaglioCampi.data;

export default dettaglioProdottoSlice.reducer;