export const perPageDomain = [10, 25, 50];

export const optionsCheckboxAsSelectField = [
    { codice: null, descrizione: "Null"},
    { codice: true, descrizione: 'True' },
    { codice: false, descrizione: 'False'}
]

export const optionsCheckboxFlagProdottoPadre = [
    { codice: false, descrizione: 'Prodotto figlio' },
    { codice: true, descrizione: 'Prodotto Padre' }
]

export const tipoProdottoFiltroRicercaLinee = [
    { codice: 'GP', descrizione: 'Gestione Patrimoniale Personalizzate' },
    { codice: 'AZ', descrizione: 'Universal Personalizzate' }
];

export const tipoProdottoIniziale = 'AFB';