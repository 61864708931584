import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import {getAPIDefaultListState} from "../../shared/functions";
import { RecuperaListaProdottiParams, Status } from "../../shared/interface";
import { recuperaListaProdoti } from "./ListaProdottiAPI";

const initialState = {
  listaProdotti: getAPIDefaultListState(),
  listaProdottiStatus: Status.idle
}

export const getListaProdotti = createAsyncThunk(
  'catalogoProdotti/ricercaProdotti',
  async (filtri?: RecuperaListaProdottiParams) => {
    const response = await recuperaListaProdoti(filtri);
    return response.data;
  }
);

export const resetListaProdotti = createAsyncThunk(
    'catalogoProdotti/resetProdotti',
    async () => { return getAPIDefaultListState() }
)

export const listaProdottiSlice = createSlice({
  name: 'listaProdotti',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListaProdotti.pending, (state) => {
        state.listaProdottiStatus = Status.start;
      })
      .addCase(getListaProdotti.fulfilled, (state, action) => {
        state.listaProdottiStatus = Status.success;
        state.listaProdotti = action.payload;
      })
      .addCase(getListaProdotti.rejected, (state) => {
        state.listaProdottiStatus = Status.failed;
      })
        .addCase(resetListaProdotti.pending, (state) => {
          state.listaProdottiStatus = Status.start;
        })
        .addCase(resetListaProdotti.fulfilled, (state, action) => {
          state.listaProdottiStatus = Status.success;
          state.listaProdotti = action.payload;
        })
        .addCase(resetListaProdotti.rejected, (state) => {
          state.listaProdottiStatus = Status.failed;
        })
  },
});

export const selectListaProdotti = (state: RootState) => state.listaProdottiStore.listaProdotti.data;

export const selectPagination = (state: RootState) => state.listaProdottiStore.listaProdotti.pagination;

export default listaProdottiSlice.reducer;