import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { Status } from "../../shared/interface";
import { inserisciProdotto, mappingCensimentoProdottoAPI } from "./CensimentoProdottoAPI";

const initialState = {
    censimentoProdottoStatus: Status.idle,
    mappingCensimentoProdottoList: {} as any,
    mappingCensimentoProdottoStatus: Status.idle
}

export const censimentoProdotto = createAsyncThunk(
    'catalogoProdotti/CensimentoProdotto',
    async (body: any) => {
      const response = await inserisciProdotto(body);
      return response.data;
    }
);

export const getMappingCensimentoProdotto = createAsyncThunk(
    'catalogoProdotti/MappingCensimentoProdotto',
    async () => {
      const response = await mappingCensimentoProdottoAPI();
      return response.data;
    }
);

export const censimentoProdottoSlice = createSlice({
    name: 'censimentoProdotto',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(censimentoProdotto.pending, (state) => {
          state.censimentoProdottoStatus = Status.start;
        })
        .addCase(censimentoProdotto.fulfilled, (state, action) => {
            state.censimentoProdottoStatus = Status.success;
        })
        .addCase(censimentoProdotto.rejected, (state) => {
        state.censimentoProdottoStatus = Status.failed;
        })
        .addCase(getMappingCensimentoProdotto.pending, (state) => {
          state.mappingCensimentoProdottoStatus = Status.start;
        })
        .addCase(getMappingCensimentoProdotto.fulfilled, (state, action) => {
          state.mappingCensimentoProdottoStatus = Status.success;
          state.mappingCensimentoProdottoList = action.payload;
        })
        .addCase(getMappingCensimentoProdotto.rejected, (state) => {
          state.mappingCensimentoProdottoStatus = Status.failed;
        })
    },
});

export const selectMappingCensimentoProdotto = (state: RootState) => state.censimentoProdottoStore.mappingCensimentoProdottoList.data;
export default censimentoProdottoSlice.reducer;