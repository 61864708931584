import React, {ReactNode, useContext, useEffect, useState} from "react";
import {
  getDettaglioProdotto,
  getMappingDettaglioCampi,
  selectDettaglioProdotto,
  selectMappingDettaglioCampi
} from "./DettaglioProdottoSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {Field, Form, Formik} from "formik";
import {Button} from "react-bootstrap";
import {UserContext} from "../../../App";
import {
  getElement,
  getFormikValuesPut,
  getMappingStructure,
  getOptionsFromDomain,
  isUserEditor
} from "../../shared/functions";
import {DateField} from "../../shared/FormikFields/DateField";
import {SelectField} from "../../shared/FormikFields/SelectField";
import {selectListaDomain} from "../domain/ListaDomainSlice";
import {AsyncSelectField} from "../../shared/FormikFields/AsyncSelectField";
import {MappingDettaglio} from "../../shared/interface";

interface DettaglioProps {
  codTitolo: any;
  callback: (formData: any) => void;
  closeModal: () => void;
}

export function DettaglioProdotto(props: DettaglioProps) {
  const listaDomain = useAppSelector(selectListaDomain);
  const mappingDettaglioCampi = useAppSelector(selectMappingDettaglioCampi);
  const [editMode, setEditMode] = useState(false);
  const [mappingStructure, setMappingStructure] = useState({} as MappingDettaglio);
  const [userHasPermission, setUserHasPermission] = useState(false);
  const dispatch = useAppDispatch();
  const dettaglioProdotto = useAppSelector(selectDettaglioProdotto);
  const user = useContext(UserContext);
  const detailFormFields = [{ name: 'codTitolo', label: 'Codice Titolo', type: 'text' }, { name: 'desProdotto', label: 'Descrizione', type: 'text' },
  { name: 'ISIN', label: 'ISIN', type: 'text' }, { name: 'codTipoStrumento', label: 'Macro categoria', type: 'select', optionsName: 'cf_tipo_titolo' }, { name: 'codSottotipoStrumento', label: 'Categoria', type: 'select', optionsName: 'cf_sottotipo_titolo' },
  { name: 'desEmittente', label: 'Emittente', type: 'asyncSelect', optionsName: 'emittente_con_nazione', dependency: 'codPaeseEmissione', dependencyPrefix: '|' },
  { name: 'desTipoEmittente', label: 'Tipologia emittente', type: 'text' }, { name: 'dataEmissione', label: 'Data Emissione', type: 'date' }, { name: 'dataScadenza', label: 'Data scadenza', type: 'date' },
  { name: 'codDivisaEmissione', label: 'Divisa emissione', type: 'select', optionsName: 'dm_divisa' }, { name: 'codDivisaTrattazione', label: 'Divisa trattazione', type: 'select', optionsName: 'dm_divisa' },
  { name: 'ramoPolizza', label: 'Ramo polizza', type: 'text' }, { name: 'flagAcquistabile', label: 'Acquistabile', type: 'checkbox' }, { name: 'flagVendibile', label: 'Vendibile', type: 'checkbox' },
  { name: 'flagBlocco', label: 'Bloccato', type: 'checkbox' }, { name: 'dataApertura', label: 'Data apertura', type: 'date' }, { name: 'dataChiusura', label: 'Data chiusura', type: 'date' },
  { name: 'flagMifid', label: 'Soggetto a MIFID', type: 'checkbox' }, { name: 'qualitaProdotto', label: 'Prodotti equivalenti', type: 'select', optionsName: "prodotti_equivalenti" }, { name: 'flagProdottoCedola', label: 'Cedola', type: 'checkbox' },
  { name: 'tipoPac', label: 'Tipologia PAC', type: 'text' }, { name: 'importoLottoMinAcquisto', label: 'Lotto minimo(ctv)', type: 'text'},
  { name: 'importoLottoMinSuccessivo', label: 'Lotto min. successivo (ctv)', type: 'text'}, { name: 'quantitaLottoMinAcquisto', label: 'Lotto minimo (qta)', type: 'text' },
  { name: 'quantitaLottoMinSuccessivo', label: 'Lotto min. successivo (qta)', type: 'text' }, { name: 'flagLottoMultiplo', label: 'Acquisto per lotti multipli', type: 'checkbox' },
  { name: 'desPaeseEmissione', label: 'Paese di emissione', type: 'text' }, { name: 'flagPIR', label: 'PIR', type: 'checkbox' }, { name: 'flagPRIIP', label: 'PRIIP', type: 'checkbox' },
  { name: 'flagKID', label: 'KID', type: 'checkbox' }, { name: 'codClasseFondo', label: 'Classe Sicav', type: 'select', optionsName: 'cf_cod_classe_fondo' }, { name: 'codBisogno', label: 'Bisogno assicurativo', type: 'select', optionsName: 'cf_bisogni_assicurativi' },
  { name: 'rating', label: 'Rating', type: 'text' }, { name: 'flagKIID', label: 'KiiD', type: 'checkbox' }, { name: 'desStrumentoBreve', label: 'Descrizione breve', type: 'text' },
  { name: 'flagPirAlternative', label: 'Pir alternative', type: 'checkbox' }, { name: 'flagLoad', label: 'Load/NL', type: 'checkbox' }, { name: 'flagPinl', label: 'PINL', type: 'checkbox' },
  { name: 'flagFEQ', label: 'Abilitato a FEQ', type: 'checkbox' }, { name: 'flagPrecompila', label: 'Abilitato a Precompila', type: 'checkbox' }, { name: 'codCatMorningstar', label: 'Categoria M*', type: 'text' },
  { name: 'flagQualificatoUcits', label: 'Qualificato Ucits', type: 'checkbox' }, { name: 'flagQualificatoFia', label: 'Qualificato FIA', type: 'checkbox' },
  { name: 'flagEltif', label: 'ELTIF', type: 'checkbox' }, { name: 'flagHedge', label: 'Fondo hedged', type: 'checkbox' }, { name: 'codSicav', label: 'Codice SICAV', type: 'text' },
  { name: 'flagAperto', label: 'Fondo Aperto/Chiuso', type: 'checkbox' }, { name: 'flagRiservato', label: 'Fondo Riservato (Y/N)', type: 'checkbox' },
  { name: 'flagValid', label: 'Prodotto valido', type: 'checkbox' }];

  const initialValues = detailFormFields.reduce((acc, field) => {
    if (field.type === 'checkbox') 
      { return acc = { ...acc, [field.name]: false } }
    return acc = { ...acc, [field.name]: '' }
  }, {} as any);

  const renderField = (field: any, isDisabled: boolean, index: number) => {
    initialValues[field.name] = dettaglioProdotto[field.name];
    const label = <div className="p-2 detail-header">
      <label htmlFor={field.name} className={`fw-bold ${(editMode && isDisabled) ? 'disabled' : ''}`}>{field.label}</label>
    </div>;
    switch (field.type) {
      case 'text':
      default:
        return (
          <div key={index} className="col-3 detail">
            {label}
            <Field id={field.name} name={field.name} className="form-control" disabled={isDisabled} />
          </div>
        );
      case 'date':
        return (
          <div key={index} className="col-3 detail">
            {label}
            <Field id={field.name} name={field.name} component={DateField} className="form-control" disabled={isDisabled} placeholder=" N / A " />
          </div>
        );
      case 'checkbox':
        return (
          <div key={index} className="col-3 detail">
            {label}
            <div className="divCheck">
                <Field id={field.name} name={field.name} className="form-check-input mt-3" type="checkbox" disabled={isDisabled} />
            </div>
          </div>
        );
      case 'select':
        return (
          <div key={index} className="col-3 detail">
            {label}
            <Field id={field.name} component={SelectField} options={getOptionsFromDomain(getElement(listaDomain, field.optionsName))} name={field.name} disabled={isDisabled} />
          </div>
        );
      case 'asyncSelect':
        return (
          <div key={index} className="col-3 detail">
            {label}
            <Field id={field.name} component={AsyncSelectField}
              options={getOptionsFromDomain(getElement(listaDomain, field.optionsName,
                // controllo la dipendenza, se ha anche il prefix lo aggiungo nel term da cercare altrimenti cerco solo il valore del campo
                (field.dependency) ?
                  ((field.dependencyPrefix) ?
                    (field.dependencyPrefix + dettaglioProdotto[field.dependency]) : dettaglioProdotto[field.dependency])
                  : null))}
              name={field.name} disabled={isDisabled} />
          </div>
        );
    }
  };

  const renderDetail = () => {
    const productKeys = Object.keys(dettaglioProdotto);
    const formFields = detailFormFields.filter((field: any) => productKeys.includes(field.name));
    let detail: ReactNode[] = [];
    formFields.forEach((field, i) => {
      if (mappingStructure[field.name]) {
        const isDisabled = !editMode || !mappingStructure[field.name].isEditable;
        if (mappingStructure[field.name].isVisibile) {
          detail.push(renderField(field, isDisabled, i));
        }
      }
    });
    return detail;
  };

  const handleAnnulla = (resetForm:any) => {
    setEditMode(false);
    resetForm();
  }

  useEffect(() => {
    if (user?.uid) {
      user.getIdToken(true).then((token: string) => setUserHasPermission(isUserEditor(token)));
    }
  });

  useEffect(() => {
    dispatch(getMappingDettaglioCampi(props.codTitolo));
    dispatch(getDettaglioProdotto(props.codTitolo));
  }, [dispatch, props.codTitolo]);

  useEffect(() => {
    setMappingStructure(getMappingStructure(mappingDettaglioCampi));
  }, [mappingDettaglioCampi]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(data, actions) => {
        actions.setSubmitting(false);
        props.callback({ codTitolo: props.codTitolo, data: getFormikValuesPut(data) });
      }}>
      {({resetForm}) => (
          <Form>
            {
              <>
                <div className="text-end m-2" >
                  {!editMode && userHasPermission &&
                      <Button variant="primary" type="button" onClick={() => setEditMode(true)}>
                        Modifica
                      </Button>}
                  {editMode &&
                      <div>
                        <Button className="me-3" variant="secondary" onClick={() => handleAnnulla(resetForm)}>
                          Annulla
                        </Button>
                        <Button variant="danger" type="submit">
                          Conferma
                        </Button>
                      </div>
                  }
                </div>
                <div className="row text-center">
                  {renderDetail()}
                </div>
              </>
            }
          </Form>)
      }
    </Formik>
  );
}
