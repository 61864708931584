export enum Status {
  'idle',
  'start',
  'success',
  'failed'
}

export interface RecuperaListaProdottiParams {
  codTipoStrumento?: string,
  codSottotipoStrumento?: string,
  desProdotto?: string,
  isinCodTitolo?: string,
  ISIN?: string,
  codTItolo?: string,
  desEmittente?: string,
  dataEmissione?: Date,
  dataScadenza?: Date,
  flagIncludiNonValidi?: boolean,
  flagMifid?: boolean,
  qualitaProdotto?: string,
  page?: number,
  per_page?: number
}

export interface ModalitaServizio {
  codModalitaServizio: string,
  desModalitaServizio: string,
  dataInizio?: Date,
  dataFine?: Date,
  flagAttivo?: boolean,
  flagAttivabile?: boolean
}

export interface FormField {
  name: string,
  placeholder: string,
  optionsName?: string,
  className?: any,
  col?: string,
  type?: string,
  dependency?: string,
  objectDependency?: string,
  default?: any,
  jsonObject?: String,
  maxLength?:number,
  isRequired?:boolean,
  toolTip?:string,
  disabledOnTrueField?:string,
  scomparto?:string
}

export interface FormFilter {
  base: FormField[],
  advanced: FormField[]
}

export interface dominio {
  codice: any,
  descrizione: string,
  dependency?: string
}

export interface dominioList {
  nomeDominio: string,
  dettagli: dominio[]
}

export interface MappingDettaglio {
  [key: string]: { isEditable: boolean, isVisibile: boolean }
}

export interface MappingCensimentoCampi {
  [key: string]: { isEditable: boolean, isVisibile: boolean, isRequired: boolean };
}

export interface MappingCensimento {
  [key: string]: MappingCensimentoCampi
}