import axios from "axios";

export async function recuperaDettaglioProdotto(codTitolo: string) {
  return await axios
    .get('/catalogoProdottiBe/v2/letturaDettagliProdotti', { params: { codTitolo } })
    .then((response) => response);
}

export async function aggiornaDettaglioProdotto(params: any) {
  return await axios
    .put(`/catalogoProdottiBe/v2/aggiornaDatiProdotti?codTitolo=${params.codTitolo}`, { ...params.data })
    .then((response) => response);
}
export async function aggiornaCatalogoCommerciale(params:any){
  let queryParams = Object.keys(params).filter(x => x !== 'data').map(x => `${x}=${encodeURIComponent(params[x])}`).join('&');
  return await axios
    .put(`/catalogoProdottiBe/v2/aggiornaCatalogoCommerciale?${queryParams}`, params.data )
    .then((response) => response);
}

export async function mappingDettaglioCampi(codTitolo: string) {
  return await axios
    .get('/catalogoProdottiBe/v2/mappingDettaglioCampi', { params: { codTitolo } })
    .then((response) => response);
}