import React from 'react';
import  DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from 'date-fns/locale/it';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { dateFormatterForAPI } from "../../shared/functions";
registerLocale("it", it)
setDefaultLocale("it")

export const DateField = ({ field, form, className, placeholder, disabled}: any) => (
  <DatePicker
    key={`key_${field.value}`}
    locale="it"
    dateFormat="dd/MM/yyyy"
    autoComplete="off"
    placeholderText={placeholder}
    className={className}
    showYearDropdown
    yearDropdownItemNumber={15}
    scrollableYearDropdown
    closeOnScroll={true}
    startDate={new Date()}
    name={field.name}
    selected={ (field.value && new Date(field.value)) || null}
    onChange={(date:any) => {form.setFieldValue(field.name, dateFormatterForAPI(date))}}
    disabled={disabled}
    isClearable={!disabled}

    strictParsing
  />
)