import {Field, Form, Formik} from "formik";
import React, {ReactNode, useContext, useEffect, useRef, useState} from "react";
import Switch from "react-switch";
import {Accordion, Button, Table} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {CustomModal} from "../../shared/CustomModal/CustomModal";
import {
  dateFormatterToShow,
  getElement,
  getFormikValues,
  getOptionsFromDomain
} from "../../shared/functions";
import {getListaProdotti, resetListaProdotti, selectListaProdotti, selectPagination} from "./ListaProdottiSlice";
import {ITEMS_PER_PAGE} from "../../../app/conf";
import {FormField, FormFilter, RecuperaListaProdottiParams, Status} from "../../shared/interface";
import {Paginator} from "../../paginator/Paginator";
import {UserContext} from "../../../App";
import {DettaglioCatalogoCommerciale} from "../dettaglio/DettaglioCatalogoCommerciale";
import {DettaglioProdotto} from "../dettaglio/DettaglioProdotto";
import {SelectField} from "../../shared/FormikFields/SelectField";
import {DateField} from "../../shared/FormikFields/DateField";
import {selectListaDomain} from "../domain/ListaDomainSlice";

import {
  putCatalogoCommerciale,
  putDettaglioProdotto,
  selectAggiornaDettaglioProdottoStatus
} from "../dettaglio/DettaglioProdottoSlice";
import {AsyncSelectField} from "../../shared/FormikFields/AsyncSelectField";

export function ListaProdotti() {
  const dispatch = useAppDispatch();
  const listaProdotti = useAppSelector(selectListaProdotti);
  const listaDomain = useAppSelector(selectListaDomain);
  const paginationItem = useAppSelector(selectPagination);
  const aggiornaDettaglioStatus = useAppSelector(selectAggiornaDettaglioProdottoStatus);
  const [advanceFilter, setAdvanceFilter] = useState(false);
  const [showCC, setShowCC] = useState(false);
  const [showDettaglioProdotto, setShowDettaglioProdotto] = useState(false);
  const [codTitolo, setCodTitolo] = useState('');
  const [indexCatalogoCommerciale, setIndexCatalogoCommerciale] = useState(-1);
  const [showDetailConfirm, setShowDetailConfirm] = useState(false);
  const [filterText, setFilterText] = useState('Filtri Adv.');
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(ITEMS_PER_PAGE);
  const [pressed, setPressed] = useState(false);
  const user = useContext(UserContext);
  const [ricercaCodTitolo, setRicercaCodTitolo] = useState(false);
  const [accordionKeyActive, setAccordionKeyActive] = useState([] as string[]);

  let proceedDetailState = useRef(null as any);

  const initialValues: RecuperaListaProdottiParams = {
    codTipoStrumento: '',
    codSottotipoStrumento: '',
    desProdotto: '',
    isinCodTitolo: '',
    desEmittente: '',
    dataEmissione: undefined,
    dataScadenza: undefined,
    flagMifid: false,
    flagIncludiNonValidi: false,
    qualitaProdotto: '',
    page: page,
    per_page: perPage
  }

  const formFields: FormFilter = {
    base: [{
      name: 'isinCodTitolo',
      placeholder: '',
      type: 'isinCodTitoloType',
      className: 'form-control',
      col: 'col-2',
    }, {
      name: 'desProdotto',
      placeholder: 'Descrizione',
      type: 'text',
      className: 'form-control',
      col: 'col-4'
    }, {
      name: 'desEmittente',
      placeholder: 'Emittente',
      type: 'AsyncSelectField',
      optionsName: "cf_emittente",
      col: "col-4"
    }],
    advanced: [{
      name: 'codTipoStrumento',
      placeholder: 'Macro categoria',
      type: 'select',
      optionsName: "cf_tipo_titolo",
      col: "col-3"
    }, {
      name: 'codSottotipoStrumento',
      placeholder: 'Categoria',
      type: 'select',
      optionsName: 'cf_sottotipo_titolo',
      dependency: 'codTipoStrumento', //nome del formValue
      col: "col-3"
    }, {
      name: 'qualitaProdotto',
      placeholder: 'Prodotti equivalenti',
      type: 'select',
      optionsName: "prodotti_equivalenti",
      col: "col-3"
    }, {
      name: 'dataScadenza',
      placeholder: 'Data scadenza',
      type: 'date',
      className: "form-control",
      col: "col-2"
    }, {
      name: 'dataEmissione',
      placeholder: 'Data Emissione',
      type: 'date',
      className: "form-control",
      col: "col-2"
    },{
      name: 'flagMifid',
      placeholder: 'Soggetto a MIFID',
      className: 'form-check-input mx-auto',
      type: 'checkbox',
      col: "col-2 text-center"
    },{
      name: 'flagIncludiNonValidi',
      placeholder: 'Includi prodotti non validi',
      className: 'form-check-input mx-auto',
      type: 'checkbox',
      col: "col-3 text-center"
    }]
  };

  const [formValues, setFormValues] = useState({} as RecuperaListaProdottiParams);

  const handleAdvanceFilter = (isAdvanced: boolean) => {
    if (isAdvanced) {
      setFilterText('Filtri Base');
    } else {
      setFilterText('Filtri Adv.');
    }
    setAdvanceFilter(isAdvanced);
  }
  const handleCloseCatalogoCommerciale = () => setShowCC(false);
  const handleCloseDettaglioProdotto = () => setShowDettaglioProdotto(false);
  const handleAlertDetail = () => setShowDetailConfirm(false);

  const proceedDetail = (detailFormData:any) => {
    dispatch(putDettaglioProdotto(detailFormData));
    handleAlertDetail();
  };

  const proceedCatalogoCommerciale = (detailFormDataCatalogoCommerciale:any) => {
    dispatch(putCatalogoCommerciale(detailFormDataCatalogoCommerciale));
    handleAlertDetail();
  }

  const handleOpenAccordion = (index: number) => {
    let temp = Object.assign([], accordionKeyActive);

    if(!temp.includes(index.toString()))
      temp.push(index.toString())
    else
      temp = temp.filter((x:any) => x !== index.toString());

    setAccordionKeyActive(temp);
  }

  const handleShowDettaglioProdotto = (cod: string) => {
    setCodTitolo(cod);
    setShowDettaglioProdotto(true);
  }

  const handleShowCatalogoCommerciale = (cod: string, index:number) => {
    setCodTitolo(cod);
    setIndexCatalogoCommerciale(index);
    setShowCC(true);
  }

  const handleConfirmDettaglio = (formData: any) => {
    setShowDettaglioProdotto(false);
    proceedDetailState.current = () => proceedDetail(formData);
    setShowDetailConfirm(true);
  }
  
  const handleConfirmDettaglioCatalogoCommerciale = (formData : any) => {
    setShowCC(false);
    proceedDetailState.current = () => proceedCatalogoCommerciale(formData);
    setShowDetailConfirm(true);
  }

  const paginationChanged = (p: number) => setPage(p);
  const perPageChanged = (perPageNew: number) => setPerPage(perPageNew);

  const handleReset = () => {
    setPressed(false);
    setPage(0);
    setPerPage(ITEMS_PER_PAGE);
    setFormValues(initialValues);
    dispatch(resetListaProdotti());
  }

  const renderField = (field: FormField, i: number, values:any) => {
    switch (field.type){
      case 'text':
        return(
            <div key={i} className={"m-2 flex-fill " + field.col}>
              <Field id={field.name} name={field.name} placeholder={field.placeholder} className={field.className} />
            </div>
        );
      case 'select':
        return(
            <div key={i} className={"m-2 flex-fill " + field.col}>
              {field.dependency ?
                  <Field id={field.name} component={SelectField} options={getOptionsFromDomain( values[field.dependency] ? getElement(listaDomain, field.optionsName)?.filter(x => x.dependency === values[field?.dependency!]) : getElement(listaDomain, field.optionsName))}   //x.dependency deve essere il codice non la descrizione
                         name={field.name} placeholder={field.placeholder} className={field.className} /> :
                  <Field id={field.name} component={SelectField} options={getOptionsFromDomain(getElement(listaDomain, field.optionsName))}
                         name={field.name} placeholder={field.placeholder} className={field.className} />
              }
            </div>
        );
      case 'AsyncSelectField':
        return(
            <div key={i} className={"m-2 flex-fill " + field.col}>
              <Field id={field.name} component={AsyncSelectField} options={getOptionsFromDomain(getElement(listaDomain, field.optionsName))}
                     name={field.name} placeholder={field.placeholder} className={field.className} />
            </div>
        );
      case 'checkbox':
      return (
          <div key={i} className={"m-2 flex-fill " + field.col}>
            <div className="d-flex flex-column">
              <label htmlFor={field.name} className="fw-bold mx-2">{field.placeholder}</label>
              <Field id={field.name} name={field.name} className={field.className} type="checkbox" />
            </div>
          </div>
        );
      case 'isinCodTitoloType':
        return (
            <div key={i} className={"m-2 flex-fill " + field.col}>
              <div className="d-flex flex-column">
                <Field id={field.name} name={field.name} placeholder={ricercaCodTitolo ? 'Codice Prodotto' : 'ISIN'} className={field.className} />
                <label className="mt-2 mx-2 align-middle">
                  <span className="vcenter">ISIN</span>
                  <Switch className="mx-2 vcenter" checkedIcon={false} uncheckedIcon={false} height={13} width={40} onColor="#2e6cd1" offColor="#2e6cd1" onChange={() => setRicercaCodTitolo(!ricercaCodTitolo)} checked={ricercaCodTitolo}/>
                  <span className="vcenter">Codice Prodotto</span>
                </label>
              </div>
            </div>
        );
      case 'date':
        return (
            <div key={i} className={"m-2 flex-fill " + field.col}>
              <Field id={field.name} component={DateField} name={field.name} placeholder={field.placeholder} className={field.className} />
            </div>
        )
    }
  }

  const renderFilterFields = (filtroFields: any, values: any) => {
    let fields: ReactNode[] = [];
    filtroFields.forEach((field:FormField, i:number) => {
      fields.push(renderField(field, i, values))
    })
    return fields;
  }

  /* for reset, cerca, cambio page, cambio per_page */
  useEffect(() => {
    const submit = () => {
      setAccordionKeyActive([]); //per chiudere tutti gli accordion
      if (user?.uid){
        const formValuesForRicerca = { ...formValues };
        delete formValuesForRicerca["isinCodTitolo"];

        dispatch(getListaProdotti({ ...getFormikValues(formValuesForRicerca), page: page, per_page: perPage }));
      }
    }

    if (pressed)
      submit();

  }, [user, formValues, page, perPage, dispatch, pressed]);

  useEffect(() => {
    if (user?.uid) {
      if (aggiornaDettaglioStatus === Status.success) {
        const formValuesForRicerca = {...formValues};
        delete formValuesForRicerca["isinCodTitolo"];

        dispatch(getListaProdotti({ ...getFormikValues(formValuesForRicerca), page: page, per_page: perPage }));
      }
    }
  }, [formValues, page, perPage, dispatch, aggiornaDettaglioStatus, user]);

  return (
    <>
      {/* Modal per catalogo commerciale */}
      <CustomModal
        show={showCC}
        cancelFnc={handleCloseCatalogoCommerciale}
        size="lg"
        title="Catalogo commerciale"
        component={<DettaglioCatalogoCommerciale callback={handleConfirmDettaglioCatalogoCommerciale} closeModal={handleCloseCatalogoCommerciale} codTitolo={codTitolo} indexCatalogoCommerciale={indexCatalogoCommerciale}/>}
        confirmFnc={handleCloseCatalogoCommerciale}
        hideFooter
      />
      {/* Modal per dettaglio */}
      <CustomModal
        show={showDettaglioProdotto}
        cancelFnc={handleCloseDettaglioProdotto}
        size="xl"
        title="Dettaglio prodotto"
        component={<DettaglioProdotto callback={handleConfirmDettaglio} closeModal={handleCloseDettaglioProdotto} codTitolo={codTitolo} />}
        confirmFnc={handleCloseDettaglioProdotto}
        hideFooter
      />
      {/* Modal per conferma modifica dettaglio/cc */}
      <CustomModal
        show={showDetailConfirm}
        cancelFnc={handleAlertDetail}
        showCancel
        title="Attenzione"
        component={
          <>
            Confermare la modifica dei dati?
          </>
        }
        confirmFnc={proceedDetailState.current}
      />

      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors = {} as any;
          if (!values.isinCodTitolo && !values.desProdotto && !values.desEmittente) {
            errors.isinCodTitolo = 'Inserire almeno uno tra ISIN / Codice prodotto, Descrizione o Emittente';
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          const filtriRicerca = {
            ...values, ISIN: (!ricercaCodTitolo) ? values.isinCodTitolo : "", codTitolo: (ricercaCodTitolo) ? values.isinCodTitolo : ""
          } as RecuperaListaProdottiParams;
          setFormValues(filtriRicerca);
          setPage(0);
          setPressed(true);
          actions.setSubmitting(false);
        }}>
        {({ errors, values }) => (
          <Form>
            {<>
              <div className="d-flex align-items-center">
                <div className="d-flex flex-grow-1 flex-column">
                  <div className="bg-light p-1 rounded d-flex flex-column flex-fill">
                    <div className="d-flex flex-fill flex-wrap">
                      {renderFilterFields(formFields.base, values)}
                    </div>
                    {advanceFilter &&
                        <div className="d-flex flex-fill">
                          {renderFilterFields(formFields.advanced, values)}
                        </div>
                    }
                  </div>
                </div>
                <div className="d-flex flex-column mx-2">
                  <div className="m-1">
                    <Button variant="primary" className="btn-ricerca" onClick={() => { handleAdvanceFilter(!advanceFilter) }}>{filterText}</Button>
                  </div>
                  <div className="m-1">
                    <Button variant="primary" className="btn-ricerca" type="submit">Cerca</Button>
                  </div>
                  <div className="m-1">
                    <Button variant="primary" className="btn-ricerca" type="reset" onClick={handleReset}>Reset</Button>
                  </div>
                </div>
              </div>
              <div className="errors m-2">{errors ? errors.isinCodTitolo : ''}</div>
              </>
            }
          </Form>
        )}
      </Formik>
      {
        listaProdotti != null && listaProdotti.length > 0 ?
          <>
            <Paginator page={page} perPage={perPage} totalItems={paginationItem.count}
              callback={paginationChanged} callbackPerPage={perPageChanged}
              hidePerPage={false} perPageDomain={[10, 25, 50]}></Paginator>
            {listaProdotti.map((element: any, index: number) =>
              <Accordion key={index} activeKey={accordionKeyActive} className="mb-3 product">
                <Accordion.Item eventKey={index.toString()}>
                  <Accordion.Header onClick={() => handleOpenAccordion(index)}>
                    <span className="d-flex w-100 align-items-center justify-content-between">
                      <div>
                        <div className="mb-3 d-flex flex-row">
                          <span className="mx-2 spanWidthProduct"><span className="fw-bold">Prodotto: </span> {element.prodotto}</span>
                          <span className="mx-2 spanWidth"><span className="fw-bold">Emittente: </span>{element.desEmittente}</span>
                          <span className="mx-2 spanWidth"><span className="fw-bold">Descrizione: </span>{element.desProdotto}</span>
                        </div>
                        <div className="mt-3 d-flex flex-row flex-fill">
                          <span className="mx-2 spanWidthProduct"><span className="fw-bold">Macro categoria: </span>{element.desTipoStrumento}</span>
                          <span className="mx-2 spanWidth"><span className="fw-bold">Categoria: </span>{element.desSottotipoStrumento}</span>
                          <span className="mx-2 spanWidth"></span>
                        </div>
                      </div>
                      { (element.prodotti[0].codTitolo.substring(0,2) !=='AT' && element.prodotti[0].codTitolo.substring(0,2) !=='SM') &&
                        <div onClick={(e: any) => handleShowCatalogoCommerciale(element.prodotti[0].codTitolo, index)} className="mx-3 btn btn-primary btn-cc">catalogo<br/>commerciale</div>
                      }
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Table className="mt-1" responsive hover>
                      <thead>
                        <tr>
                          <th>Codice titolo</th>
                          <th>Data emissione</th>
                          <th>Data scadenza</th>
                          <th>Prodotti equivalenti</th>
                          <th>Soggetto a Mifid</th>
                          <th>Valido</th>
                        </tr>
                      </thead>
                      <tbody>
                        {element.prodotti.map((item: any, i: number) =>
                          <tr key={i} className={!item.flagValid ? 'disabled' : ''} onClick={() => handleShowDettaglioProdotto(item.codTitolo)}>
                            <td>{item.codTitolo}</td>
                            <td>{dateFormatterToShow(item.dataEmissione)}</td>
                            <td>{dateFormatterToShow(item.dataScadenza)}</td>
                            <td>{(item.qualitaProdotto === null) ? "" : (item.qualitaProdotto === "1") ? 'Si' : 'No'}</td>
                            <td>{(item.flagMifid === null) ? "" : (item.flagMifid) ? 'Si' : 'No'}</td>
                            <td>{(item.flagValid === null) ? "" : (item.flagValid) ? 'Si' : 'No'}</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}
            <Paginator page={page} perPage={perPage} totalItems={paginationItem.count}
              callback={paginationChanged} callbackPerPage={perPageChanged}
              hidePerPage={false} perPageDomain={[10, 25, 50]}></Paginator>
          </>
          :  <div className="d-flex justify-content-center mt-3"><h4>Nessun risultato disponibile.</h4></div>
      }
    </>
  )
}