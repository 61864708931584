import React, {ReactNode, useContext, useEffect, useState} from 'react';
import {Button} from "react-bootstrap";
import {Field, Form, Formik} from "formik";
import "react-datepicker/dist/react-datepicker.css";
import {DateField} from '../../shared/FormikFields/DateField';
import {dateFormatterForAPI, getBodyObjectWithNulls, getElement, getMappingStructureCensimento, getOptionsFromDomain, isUserAdmin, noNullNoUndefinedNoEmptyString} from '../../shared/functions';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {FormField} from '../../shared/interface';
import {AsyncSelectField} from '../../shared/FormikFields/AsyncSelectField';
import {SelectField} from '../../shared/FormikFields/SelectField';
import {selectListaDomain} from '../domain/ListaDomainSlice';
import {censimentoProdotto, selectMappingCensimentoProdotto} from "./CensimentoProdottoSlice";
import {optionsCheckboxAsSelectField, optionsCheckboxFlagProdottoPadre, tipoProdottoIniziale} from '../../shared/domains';
import { SelectBooleanField } from '../../shared/FormikFields/SelectBooleanField';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { UserContext } from '../../../App';
import { REACT_APP_ENABLE_CENSIMENTO_PRODOTTO } from '../../../app/conf';

export function CensimentoProdotto(){

    const user = useContext(UserContext);
    const dispatch = useAppDispatch();
    const listaDomain = useAppSelector(selectListaDomain);
    const mappingCensimento = useAppSelector(selectMappingCensimentoProdotto);
    const dateNow = new Date();
    const [showDefaultForms, setShowDefaultForms] = useState({state:false, text: 'MOSTRA'});

    const handleShowDefaultForms = () => {
        if(showDefaultForms.state === false)
            setShowDefaultForms({state:true, text: 'NASCONDI'})
        else
            setShowDefaultForms({state:false, text: 'MOSTRA'})
    }
    const mappingCensimentoMap = {} as any;

    mappingCensimento.map((x:any) => x.tipoProdotto).forEach(
        (x:any) => {
            let mappingData = mappingCensimento.find((a:any) => a.tipoProdotto === x);
            mappingCensimentoMap[x] = {
                campiBase : getMappingStructureCensimento({campiEditabili: mappingData.campiBase.campiEditabili, campiVisibili: mappingData.campiBase.campiVisibili }),
                catalogoCommerciale: mappingData.catalogoCommerciale,
                flagProdottoPadre: mappingData.flagProdottoPadre
            };
        }
    );

    const [userHasPermission, setUserHasPermission] = useState(false);

    /* Le informazioni su che campi mostrare e quali rendere editabili arrivano dal back nell'API di mappingCensimento */
    const detailFormFields = {
        base: [
            { scomparto: "campiGenerici", jsonObject: 'titolo', name: 'codTitolo', placeholder: 'Codice Titolo', type: 'text', default: ''},
            { scomparto: "campiGenerici", jsonObject: 'titolo', name: 'desTitolo', placeholder: 'Descrizione Comparto', type: 'text', default: ''},
            { scomparto: "campiGenerici", jsonObject: 'titolo', name: 'desTitoloBreve', placeholder: 'Descrizione Comparto Breve', type: 'text', default: '', maxLength: 50},
            { scomparto: "campiGenerici", jsonObject: 'titolo', name: 'codSocProd', placeholder: 'Codice Società Prodotto', type: 'select', optionsName: 'cf_soc_prod', default: ''},
            { scomparto: "campiGenerici", jsonObject: 'titolo', name: 'codEmittente', placeholder: 'Società Emittente', type: 'AsyncSelectField', optionsName: 'emittente_con_nazione'},
            { scomparto: "campiGenerici", jsonObject: 'titolo', name: 'dataEmissione', placeholder: 'Inizio Collocamento', type: 'date'},
            { scomparto: "campiGenerici", jsonObject: 'titolo', name: 'dataScadenza', placeholder: 'Chiusura collocamento', type: 'date' },
            { scomparto: "campiGenerici", jsonObject: 'titolo', name: 'codDivisaEmissione', placeholder: 'Divisa Classe Emissione', type: 'select', optionsName: 'dm_divisa', default: ''},
            { scomparto: "campiGenerici", jsonObject: 'titolo', name: 'codDivisaTrattazione', placeholder: 'Divisa Classe Trattazione',type: 'select', optionsName: 'dm_divisa', default: ''},
            { scomparto: "campiGenerici", jsonObject: 'titolo', name: 'flagProdottoContenitore', placeholder: 'Flag Prodotto Contenitore', type: 'checkbox', default: null },
            { scomparto: "campiGenerici", jsonObject: 'titolo', name: 'flagPRIIP', placeholder: 'Informativa PRIIP', type: 'checkbox', default: null },
            { scomparto: "campiGenerici", jsonObject: 'titolo', name: 'flagKID', placeholder: 'Informativa KID', type: 'checkbox', default: null },
            { scomparto: "campiGenerici", jsonObject: 'titolo', name: 'flagKIID', placeholder: 'Informativa KIID', type: 'checkbox', default: null },
            { scomparto: "campiGenerici", jsonObject: 'titolo', name: 'codTipoTitolo', placeholder: 'Macro Categoria', type: 'select', optionsName: 'cf_tipo_titolo', default: ''},
            { scomparto: "campiGenerici", jsonObject: 'titolo', name: 'codSottotipoTitolo', placeholder: 'Categoria', type: 'select', optionsName: 'cf_sottotipo_titolo',  objectDependency: 'titolo', dependency: 'codTipoTitolo', default: '' },
            { scomparto: "campiGenerici", jsonObject: 'titolo', name: 'codTipoGestione', placeholder: 'Tipologia Gestione', type: 'select', optionsName: 'cf_tipo_gestione', default: ''},
            { scomparto: "campiGenerici", jsonObject: 'titolo', name: 'dataApertura', placeholder: 'Data Apertura', type: 'date', default: dateFormatterForAPI(dateNow)},
            { scomparto: "campiGenerici", jsonObject: 'titolo', name: 'flagMifid', placeholder: 'Flag Mifid', type: 'checkbox', default: null },
            { scomparto: "campiGenerici", jsonObject: 'titolo', name: 'flagFEQ', placeholder: 'Flag FEQ', type: 'checkbox', default: false },
            { scomparto: "campiGenerici", jsonObject: 'titolo', name: 'flagPrecompila', placeholder: 'Flag Precompila', type: 'checkbox', default: null },
            { scomparto: "campiGenerici", jsonObject: 'titolo', name: 'flagBloccoFE', placeholder: 'Flag Blocco FE', type: 'checkbox', default: null },
            { scomparto: "campiGenerici", jsonObject: 'titolo', name: 'flagPINL', placeholder: 'Flag PINL', type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: 'titolo', name: 'qualitaProdotto', placeholder: 'Qualità Prodotto', type: 'text', default: '' },
            { scomparto: "campiAggiuntivi", jsonObject: 'fondo', name: 'codClasseFondo', placeholder: 'Codice Classe Fondo', type: 'select', optionsName: 'cf_cod_classe_fondo', default: '' },
            { scomparto: "campiAggiuntivi", jsonObject: 'fondo', name: 'flagPIRUcits', placeholder: 'Flag PIR Ucits', type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: 'fondo', name: 'flagPIRAlternative', placeholder: 'Flag PIR Alternative', type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: 'fondo', name: 'flagPIC', placeholder: 'Flag PIC', type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: 'fondo', name: 'flagPAC', placeholder: 'Flag PAC', type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: 'fondo', name: 'flagMultipac', placeholder: 'Flag Multipac', type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: 'fondo', name: 'flagLoad', placeholder: 'Flag Load', type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: 'fondo', name: 'flagNoLoad', placeholder: 'Flag NoLoad', type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: 'fondo', name: 'ISIN', placeholder: 'ISIN', type: 'text', default: ''},
            { scomparto: "campiAggiuntivi", jsonObject: 'fondo', name: 'importoMinimo', placeholder: 'Importo Minimo', type: 'number', default: '' },
            { scomparto: "campiAggiuntivi", jsonObject: 'fondo', name: 'importoMinimoSucc', placeholder: 'Importo Minimo Successivo', type: 'number', default: '' },
            { scomparto: "campiAggiuntivi", jsonObject: 'fondo', name: 'quantitaMinima', placeholder: 'Quantita Minima', type: 'number', default: '' },
            { scomparto: "campiAggiuntivi", jsonObject: 'fondo', name: 'quantitaMinimaSucc', placeholder: 'Quantita Minima Successiva', type: 'number', default: ''},
            { scomparto: "campiAggiuntivi", jsonObject: 'fondo', name: 'flagQualiPIRAlternative', placeholder: 'Flag Qualificato PIR Alternative', type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: 'fondo', name: 'flagQualiPIRUcits', placeholder: 'Flag Qualificato PIR Ucits', type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: 'fondo', name: 'flagHedged', placeholder: 'Flag Hedged', type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: 'fondo', name: 'codSicav', placeholder: 'Codice Sicav', type: 'text', default: ''},
            { scomparto: "campiAggiuntivi", jsonObject: 'fondo', name: 'tipoProdottoFia', placeholder: 'Tipo Prodotto Fia', type: 'text', default: '' },
            { scomparto: "campiAggiuntivi", jsonObject: 'prezzo', name: 'codDivisaPrezzo', placeholder: 'Codice Divisa Prezzo', type: 'select', optionsName: 'dm_divisa', default: '', disabledOnTrueField: 'flagProdottoPadre'},
            { scomparto: "campiAggiuntivi", jsonObject: 'prezzo', name: 'prezzoPartenza', placeholder: 'Prezzo Partenza', type: 'number', default: '', disabledOnTrueField: 'flagProdottoPadre'},
            { scomparto: "campiAggiuntivi", jsonObject: "polizza", name: "flagHaSottostanti", placeholder: "Flag Ha Sottostanti", type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: "polizza", name: "ramoPolizza", placeholder: "Ramo Polizza", type: 'text', default: '' },
            { scomparto: "campiAggiuntivi", jsonObject: "polizza", name: "codTipologiaPolizza", placeholder: "Codice Tipologia Polizza", type: 'text', default: '' },
            { scomparto: "campiAggiuntivi", jsonObject: "polizza", name: "desTipologiaPolizza", placeholder: "Descrizione Tipologia Polizza", type: 'text', default: '' },
            { scomparto: "campiAggiuntivi", jsonObject: "polizza", name: "codMacroprodotto", placeholder: "Codice Macroprodotto", type: 'text' },
            { scomparto: "campiAggiuntivi", jsonObject: "polizza", name: "codBisogniAssicurativi", placeholder: "Codice Bisogni Assicurativi", type: 'select', optionsName: 'cf_bisogni_assicurativi', default: '' },
            { scomparto: "campiAggiuntivi", jsonObject: "polizza", name: "flagConferimentoLiquiditaIniziale", placeholder: "Flag Conferimento Liquidita Iniziale", type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: "polizza", name: "flagConferimentoDaRimborsoIniziale", placeholder: "Flag Conferimento Rimborso Iniziale", type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: "polizza", name: "flagConferimentoLiquiditaSuccessiva", placeholder: "Flag Conferimento Liquidita Successiva", type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: "polizza", name: "flagPrelievoLiquidita", placeholder: "Flag Prelievo Liquidita", type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: "polizza", name: "flagConferimentoDaRimborsoSuccessivo", placeholder: "Flag Conferimento Rimborso Successivo", type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: "polizza", name: "flagSwitchProgrammato", placeholder: "Flag Switch Programmato", type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: "polizza", name: "flagTransferInIniziale", placeholder: "Flag Transfer InIniziale", type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: "polizza", name: "flagTransferInSuccessivo", placeholder: "Flag Transfer InSuccessivo", type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: "polizza", name: "flagRiscattoTotale", placeholder: "Flag Riscatto Totale", type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: "polizza", name: "flagRimborsoParziale", placeholder: "Flag Rimborso Parziale", type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: "polizza", name: "flagRibilanciamento", placeholder: "Flag Ribilanciamento", type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: "polizza", name: "flagCambioLinea", placeholder: "Flag CambioLinea", type: 'checkbox', default: null },
            { scomparto: "campiAggiuntivi", jsonObject: "polizza", name: "importoMinimo", placeholder: "Importo Minimo", type: 'number', default: '' },
            { scomparto: "campiAggiuntivi", jsonObject: "polizza", name: "importoMinimoSucc", placeholder: "Importo Minimo Successivo", type: 'number', default: '' },
            { scomparto: "campiAggiuntivi", jsonObject: "polizza", name: "percGs", placeholder: "Percentuale Gs", type: 'number', default: '' },
            { scomparto: "campiAggiuntivi", jsonObject: "polizza", name: "descrizioneEstesaBisogno", placeholder: "Descrizione Estesa Bisogno", type: 'text', default: '' },
            { scomparto: "campiAggiuntivi", jsonObject: 'gruppoSwitch', name: 'gruppoSwitch', placeholder: 'Gruppo Switch', type: 'text', default: '' },
            { scomparto: "campiAggiuntivi", jsonObject: 'raccordoTitoli', name: 'codTitoloPadre', placeholder: 'Codice Titolo Padre', type: 'AsyncSelectField', optionsName: 'cf_titoli_padre', disabledOnTrueField: 'flagProdottoPadre'},
            { scomparto: "campiDefault", jsonObject: 'titolo', name: 'moltiplicatore', placeholder: 'Moltiplicatore', type: 'number', default: 1},
            { scomparto: "campiDefault", jsonObject: 'titolo', name: 'dataChiusura', placeholder: 'Data Chiusura', type: 'date' },
            { scomparto: "campiDefault", jsonObject: 'titolo', name: 'flagAcquistabile', placeholder: 'Flag Acquistabile', type: 'checkbox', default: true },
            { scomparto: "campiDefault", jsonObject: 'titolo', name: 'flagVendibile', placeholder: 'Flag Vendibile', type: 'checkbox', default: true },
            { scomparto: "campiDefault", jsonObject: 'titolo', name: 'flagValid', placeholder: 'Flag Valid', type: 'checkbox', default: true },
            { scomparto: "campiDefault", jsonObject: 'titolo', name: 'flagServizio', placeholder: 'Flag Servizio', type: 'checkbox', default: false },
            { scomparto: "campiDefault", jsonObject: 'titolo', name: 'flagServizioFEQ', placeholder: 'Flag Servizio FEQ', type: 'checkbox', default: false },
            { scomparto: "campiDefault", jsonObject: 'titolo', name: 'flagAttivo', placeholder: 'Flag Attivo', type: 'checkbox', default: true },
            { scomparto: "campiDefault", jsonObject: 'titolo', name: 'flagCambioStato', placeholder: 'Flag Cambio Stato', type: 'checkbox', default: false },
            { scomparto: "campiDefault", jsonObject: 'titolo', name: 'dataCambioStato', placeholder: 'Data Cambio Stato', type: 'date', default: '1970-01-01' },
            { scomparto: "campiDefault", jsonObject: 'titolo', name: 'rating', placeholder: 'Rating', type: 'text', default: '' },
            { scomparto: "campiDefault", jsonObject: 'fondo', name: 'flagNeutro', placeholder: 'Flag Neutro', type: 'checkbox', default: null },
            { scomparto: "campiDefault", jsonObject: 'fondo', name: 'sri', placeholder: 'SRI', type: 'text', default: '' },
            { scomparto: "campiDefault", jsonObject: 'fondo', name: 'costoEntrata', placeholder: 'Costo Entrata', type: 'number', default: '' },
            { scomparto: "campiDefault", jsonObject: 'fondo', name: 'costoUscita', placeholder: 'Costo Uscita', type: 'number', default: '' },
            { scomparto: "campiDefault", jsonObject: 'fondo', name: 'flagFIAAperto', placeholder: 'Flag FIA Aperto', type: 'checkbox', default: null },
            { scomparto: "campiDefault", jsonObject: 'fondo', name: 'flagEltif', placeholder: 'Flag Eltif', type: 'checkbox', default: null },
            { scomparto: "campiDefault", jsonObject: 'fondo', name: 'codCategoriaMorningstar', placeholder: 'Codice Categoria Morningstar', type: 'text', default: '' },
            { scomparto: "campiDefault", jsonObject: 'fondo', name: 'dataLastPrice', placeholder: 'Data Last Price', type: 'date', default: dateFormatterForAPI(dateNow) },
            { scomparto: "campiDefault", jsonObject: 'fondo', name: 'flagSustainableFuture', placeholder: 'Flag Sustainable Future', type: 'checkbox', default: null },
            { scomparto: "campiDefault", jsonObject: 'fondo', name: 'flagFondoAlto1', placeholder: 'Flag Fondo Alto1', type: 'checkbox', default: false },
            { scomparto: "campiDefault", jsonObject: 'fondo', name: 'flagFondoAlto2', placeholder: 'Flag Fondo Alto2', type: 'checkbox', default: false },
            { scomparto: "campiDefault", jsonObject: 'fondo', name: 'flagFiaRiservato', placeholder: 'Flag Fia Riservato', type: 'checkbox', default: null },
            { scomparto: "campiDefault", jsonObject: 'fondo', name: 'codFamiglia', placeholder: 'Codice Famiglia', type: 'text', default: '' },
            { scomparto: "campiDefault", jsonObject: 'fondo', name: 'flagFiaOscuramentoRendimenti', placeholder: 'Flag Fia Oscuramento Rendimenti', type: 'checkbox', default: null },
            { scomparto: "campiDefault", jsonObject: 'fondo', name: 'flagFiaMultirichiamo', placeholder: 'Flag Fia Multirichiamo', type: 'checkbox', default: null },
            { scomparto: "campiDefault", jsonObject: 'fondo', name: 'dataAvvaloramentoQuoteFia', placeholder: 'Data Avvaloramento Quote Fia', type: 'date', default: null },
            { scomparto: "campiDefault", jsonObject: 'prezzo', name: 'prezzoMercato', placeholder: 'Prezzo Mercato', type: 'number', default: '' },
            { scomparto: "campiDefault", jsonObject: 'prezzo', name: 'rateoLordo', placeholder: 'Rateo Lordo', type: 'number', default: '' },
            { scomparto: "campiDefault", jsonObject: 'prezzo', name: 'rateoNetto', placeholder: 'Rateo Netto', type: 'number', default: '' },
            { scomparto: "campiDefault", jsonObject: 'prezzo', name: 'rateoDisaggio', placeholder: 'Rateo Disaggio', type: 'number', default: '' },
            { scomparto: "campiDefault", jsonObject: 'prezzo', name: 'ritenutaDisaggio', placeholder: 'Ritenuta Disaggio', type: 'number', default: '' },
            { scomparto: "campiDefault", jsonObject: 'prezzo', name: 'dataRateo', placeholder: 'Data Rateo', type: 'date', default: dateFormatterForAPI(dateNow) },
            { scomparto: "campiDefault", jsonObject: 'prezzo', name: 'coefficienteCorrezione', placeholder: 'Coefficiente Correzione', type: 'number', default: '' },
            { scomparto: "campiDefault", jsonObject: 'prezzo', name: 'poolFactor', placeholder: 'Pool Factor', type: 'number', default: '' },
            { scomparto: "campiDefault", jsonObject: 'prezzo', name: 'moltiplicatore', placeholder: 'Moltiplicatore', type: 'number', default: 1 },
            { scomparto: "campiDefault", jsonObject: 'prezzo', name: 'tipoPrezzo', placeholder: 'Tipo Prezzo', type: 'text', default: '' },
            { scomparto: "campiDefault", jsonObject: 'prezzo', name: 'flagValid', placeholder: 'Flag Valid', type: 'checkbox', default: true},
            { scomparto: "campiDefault", jsonObject: 'prezzo', name: 'coefficienteIndicizzazione', placeholder: 'Coefficiente Indicizzazione', type: 'number', default: 1 },
            { scomparto: "campiDefault", jsonObject: 'prezzo', name: 'fonte', placeholder: 'Fonte', type: 'text', default: 'MANUALE' },
            { scomparto: "campiDefault", jsonObject: 'prezzo', name: 'dataPrezzo', placeholder: 'Data Prezzo', type: 'date', default: dateFormatterForAPI(dateNow) },
            
        ] as FormField[],
        catalogoCommerciale: [
            {codModalitaServizio: 'CB', desModalitaServizio: 'Consulenza Base', forms: [{name: 'flagAttivo', jsonObject: 'catalogoCommerciale', placeholder: '', type: 'checkbox', default: false}, {name: 'dataInizio', jsonObject: 'catalogoCommerciale', label: '', type: 'date' }, {name: 'dataFine', label: '', type: 'date'}] as FormField[]},
            {codModalitaServizio: 'SX', desModalitaServizio: 'Max', forms: [{name: 'flagAttivo', jsonObject: 'catalogoCommerciale', placeholder: '', type: 'checkbox', default: false}, {name: 'dataInizio', jsonObject: 'catalogoCommerciale', label: '', type: 'date'}, {name: 'dataFine', label: '', type: 'date'}] as FormField[]},
            {codModalitaServizio: 'MNE', desModalitaServizio: 'Max No Exection', forms: [{name: 'flagAttivo', jsonObject: 'catalogoCommerciale', placeholder: '', type: 'checkbox', default: false}, {name: 'dataInizio', jsonObject: 'catalogoCommerciale', label: '', type: 'date'}, {name: 'dataFine', label: '', type: 'date'}] as FormField[]},
            {codModalitaServizio: 'MX', desModalitaServizio: 'Max Fund', forms: [{name: 'flagAttivo', jsonObject: 'catalogoCommerciale', placeholder: '', type: 'checkbox', default: false}, {name: 'dataInizio', jsonObject: 'catalogoCommerciale', label: '', type: 'date'}, {name: 'dataFine', label: '', type: 'date'}] as FormField[]},
            {codModalitaServizio: 'RDC', desModalitaServizio: 'Regime di commercializzazione', forms: [{name: 'flagAttivo', jsonObject: 'catalogoCommerciale', placeholder: '', type: 'checkbox', default: false}, {name: 'dataInizio', jsonObject: 'catalogoCommerciale', label: '', type: 'date'}, {name: 'dataFine', label: '', type: 'date'}] as FormField[]}
        ]
    };

    const getInitialValuesCatalogoCommerciale = (tipoProdotto:string) => {
        let initialValuesArr : any[] = [];
        detailFormFields.catalogoCommerciale.forEach(x => {
            let obj = {} as any;

            if(mappingCensimentoMap[tipoProdotto].catalogoCommerciale.find((cc:any) => cc.codModalitaServizio === x.codModalitaServizio)?.flagAttivo! === true)
                obj = { flagAttivo: true, dataInizio: dateFormatterForAPI(dateNow), dataFine: null };
            else
                obj = { flagAttivo: false, dataInizio: null, dataFine: null}

            initialValuesArr.push({...obj, codModalitaServizio: x.codModalitaServizio, desModalitaServizio: x.desModalitaServizio });
        })
        return initialValuesArr;
    }

    const getInitialValues = (filtersDetail:FormField[]) => {
        return filtersDetail.reduce((acc, field) => {
            if(field.default !== undefined )
                return { ...acc, [field.name]: field.default };
            if(field.type === 'text')
                return { ...acc, [field.name]: '' };
            return { ...acc, [field.name]: null };
          }, {} as any)
    };

    const initialValues : any = {
        titolo: getInitialValues(detailFormFields.base.filter(x => x.jsonObject === 'titolo')),
        fondo: getInitialValues(detailFormFields.base.filter(x => x.jsonObject === 'fondo')),
        prezzo: getInitialValues(detailFormFields.base.filter(x => x.jsonObject === 'prezzo')),
        gruppoSwitch: getInitialValues(detailFormFields.base.filter(x => x.jsonObject === 'gruppoSwitch')),
        raccordoTitoli: getInitialValues(detailFormFields.base.filter(x => x.jsonObject === 'raccordoTitoli')),
        polizza: getInitialValues(detailFormFields.base.filter(x => x.jsonObject === 'polizza')),
        catalogoCommerciale: getInitialValuesCatalogoCommerciale(tipoProdottoIniziale),
        tipoProdotto: tipoProdottoIniziale,
        flagProdottoPadre: false
    };
    const renderField = (field: FormField, i: number, errors:any, values:any, disableFields:boolean) => {

        //l'input deve essere associato al nome del valore iniziale data in pasto al formik
        const inputName = field.jsonObject + '.' + field.name;
        const placeHolder = (mappingCensimentoMap[values.tipoProdotto].campiBase[field.jsonObject as any][field.name].isRequired) ? field.placeholder + "*" : field.placeholder;
        const label = 
        <div className="p-2 detail-header">
            <label htmlFor={inputName} className={`fw-bold ${(disableFields) ? 'disabled' : ''}`}>{placeHolder}</label>
            <OverlayTrigger
                key={inputName}
                placement={'top'}
                overlay={
                    <Tooltip id={`tooltip-${inputName}`}>
                        {field.toolTip !== undefined ? field.toolTip : inputName}
                    </Tooltip>
                }
                >
                <i className="bi bi-info-circle mx-3"></i>
            </OverlayTrigger>
        </div>;

        switch (field.type){
            case 'text':
                return(
                    <div key={`${i}_${field.name}`} className="col-3 detail">
                        {label}
                        <Field type="text" id={inputName} name={inputName} placeholder=" N / A" className={`form-control ${errors[inputName] ? 'border border-danger' : ''}`} 
                            disabled={disableFields} maxLength={field.maxLength} />
                    </div>
                );
            case 'number':
                return(
                    <div key={`${i}_${field.name}`} className="col-3 detail">
                        {label}
                        <Field id={inputName} name={inputName} type="number" placeholder=" N / A" className={`form-control ${errors[inputName] ? 'border border-danger' : ''}`} disabled={disableFields}/>
                    </div>
                );
            case 'select':
                return(
                    <div key={`${i}_${field.name}`} className="col-3 detail">
                        {label}
                        {field.dependency ?
                            <Field id={inputName} component={SelectField} options={getOptionsFromDomain(values[field.objectDependency as string][field.dependency] ? getElement(listaDomain, field.optionsName)?.filter(x => x.dependency === values[field.objectDependency as string][field.dependency as string]) : getElement(listaDomain, field.optionsName))}
                                    name={inputName} placeholder="Select..." className={`${errors[inputName] ? 'border border-danger rounded' : ''}`} disabled={disableFields} /> :
                            <Field id={inputName} component={SelectField} options={getOptionsFromDomain(getElement(listaDomain, field.optionsName))}
                                    name={inputName} placeholder="Select..." className={`${errors[inputName] ? 'border border-danger rounded' : ''}`} disabled={disableFields} />
                        }
                    </div>
                );
            case 'AsyncSelectField':
                return(
                    <div key={`${i}_${field.name}`} className="col-3 detail">
                        {label}
                        <Field id={inputName} component={AsyncSelectField} options={getOptionsFromDomain(getElement(listaDomain, field.optionsName))}
                                name={inputName} placeholder="Select..." className={`${errors[inputName] ? 'border border-danger rounded' : ''}`} disabled={disableFields}/>
                    </div>
                );
            case 'checkbox':
            return (
                <div key={`${i}_${field.name}`} className="col-3 detail">
                    {label}
                    <div className="divCheck">
                        <Field id={inputName} component={SelectBooleanField} options={getOptionsFromDomain(optionsCheckboxAsSelectField)}
                                                        name={inputName} placeholder="Select..." className={`${errors[inputName] ? 'border border-danger rounded' : ''}`} disabled={disableFields} />
                    </div>
                </div>
                );
            case 'date':
                return (
                    <div key={`${i}_${field.name}`} className="col-3 detail">
                        {label}
                        <Field id={inputName} component={DateField} name={inputName} placeholder=" N / A " className={`form-control ${errors[inputName] ? 'border border-danger' : ''}`} disabled={disableFields}/>
                    </div>
                )
        }
    }

    const renderOneModServ = (field: any, i:number, values:any, flagProdottoPadre:boolean) => {
        return (
            <div key={`key_[${i}]`} className="d-flex flex-row justify-content-between">
                <div className="m-2">
                    <Field id={`catalogoCommerciale[${i}].flagAttivo`} type="checkbox" name={`catalogoCommerciale[${i}].flagAttivo`}
                           disabled={flagProdottoPadre}/>
                    {field.desModalitaServizio}
                </div>
                <div className="d-flex justify-content-end">
                    <div className="mx-3">
                        <Field id={`catalogoCommerciale[${i}].dataInizio`} component={DateField} name={`catalogoCommerciale[${i}].dataInizio`}
                               placeholder=" N / A " disabled={flagProdottoPadre || !values.catalogoCommerciale[i].flagAttivo}/>
                    </div>
                    <div>
                        <Field id={`catalogoCommerciale[${i}].dataFine`} component={DateField} name={`catalogoCommerciale[${i}].dataFine`}
                               placeholder=" N / A " disabled={flagProdottoPadre || !values.catalogoCommerciale[i].flagAttivo}/>
                    </div>
                </div>
            </div>
        )
    }

    //le sole info in detailFormFields.base con scomparto = scompartoFields
    const renderFields = (errors:any, values:any, scompartoFields:string) => {

        let allFieldsFromService = mappingCensimento.find((x:any) => x.tipoProdotto === values.tipoProdotto).campiBase;

        const detailFiltersFormVisibili = detailFormFields.base.filter(     //tutti i campi scompartoFields che per quel prodotto sono visibili
            x => x.scomparto === scompartoFields && 
            allFieldsFromService.campiVisibili.map((elem:any) => elem.jsonObject + "-" + elem.name).includes(x.jsonObject + "-" + x.name)
        );

        let fields: ReactNode[] = [];
        //per ogni campi visibile
        detailFiltersFormVisibili.forEach((field:FormField, i:number) => {
                let isEditabile = allFieldsFromService.campiEditabili.map((elem:any) => elem.jsonObject + "-" + elem.name).includes(field.jsonObject + "-" + field.name);
                let disabled = field.disabledOnTrueField !== undefined ? values[field.disabledOnTrueField]! : !isEditabile;     //field.disabledOnTrueField contiene il nome dell'input dipendente, quando la dipendenza è true disabilito questo input
                fields.push(renderField({...field}, i, errors, values, disabled))
            }
        );

        return fields;
    }

    const renderCatalogoCommerciale = (values: any, errors:any) => {
        let fields: ReactNode[] = [];
        detailFormFields.catalogoCommerciale.forEach((fieldsOneModServzio:any, i:number) => {
            fields.push(renderOneModServ(fieldsOneModServzio, i, values, values.flagProdottoPadre))
        })
        return fields;
    };

    const handleOnChangeFlagProdottoPadre = (option:any, values:any, setFieldValue:any) => {
        setFieldValue("flagProdottoPadre", option?.value !== undefined ? option.value : null)
        
        if(option.value === true){  //quando flagProdottoPadre true => prezzo, raccordoTitoli, catalogoCommerciale => null
            setFieldValue('prezzo.codDivisaPrezzo', '')
            setFieldValue('prezzo.prezzoPartenza', '')
            setFieldValue('raccordoTitoli.codTitoloPadre', '')
            setFieldValue('titolo.flagVendibile', false)
            setFieldValue('titolo.flagAcquistabile', false)

            values.catalogoCommerciale.forEach((x:any, index:number) => {
                setFieldValue(`catalogoCommerciale[${index}].flagAttivo`, false)
                setFieldValue(`catalogoCommerciale[${index}].dataInizio`, null)
                setFieldValue(`catalogoCommerciale[${index}].dataFine`, null)
            })
        }
        else if (option.value === false){   //quando flagProdottoPadre false => reset a valori iniziali
            setFieldValue('prezzo.codDivisaPrezzo', initialValues.prezzo.codDivisaPrezzo)
            setFieldValue('prezzo.prezzoPartenza', initialValues.prezzo.prezzoPartenza)
            setFieldValue('raccordoTitoli.codTitoloPadre', initialValues.raccordoTitoli.codTitoloPadre)
            setFieldValue('titolo.flagVendibile', initialValues.titolo.flagVendibile)
            setFieldValue('titolo.flagAcquistabile', initialValues.titolo.flagAcquistabile)

            values.catalogoCommerciale.forEach((x:any, index:number) => {
                let indexOfInitial = initialValues.catalogoCommerciale.map((elem:any) => elem.codModalitaServizio).indexOf(x.codModalitaServizio)
                setFieldValue(`catalogoCommerciale[${index}].flagAttivo`, initialValues.catalogoCommerciale[indexOfInitial].flagAttivo)
                setFieldValue(`catalogoCommerciale[${index}].dataInizio`, initialValues.catalogoCommerciale[indexOfInitial].dataInizio)
                setFieldValue(`catalogoCommerciale[${index}].dataFine`, initialValues.catalogoCommerciale[indexOfInitial].dataFine)
            })
        }
    }

    useEffect(() => {
        if(isUserAdmin(user))
            setUserHasPermission(true);
    }, [user])

    return (
        <div className="container">
            <Formik 
                enableReinitialize
                initialValues={initialValues}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values:any, actions:any) => {
                    if(userHasPermission && REACT_APP_ENABLE_CENSIMENTO_PRODOTTO === true){
                        actions.setSubmitting(false);
                        dispatch(censimentoProdotto(getBodyObjectWithNulls({...values})));
                    }
                }}
                validate={(values) => {
                    let errors = {} as any;

                    //errors sui campi base
                    let fieldsToCheck = detailFormFields.base
                        .filter(x => Object.keys(mappingCensimentoMap[values.tipoProdotto].campiBase).includes(x?.jsonObject! as string) && 
                                    mappingCensimentoMap[values.tipoProdotto].campiBase[x.jsonObject as string][x.name as string] !== undefined);

                    fieldsToCheck.forEach(
                        (x:any) => {
                            if(mappingCensimentoMap[values.tipoProdotto].campiBase[x.jsonObject][x.name].isRequired === true &&
                                !noNullNoUndefinedNoEmptyString(values[x.jsonObject][x.name]))
                                errors[x.jsonObject + '.' + x.name] = true;
                        }
                    )
                    
                    //controlli aggiuntivi sul prezzo
                    if(noNullNoUndefinedNoEmptyString(values.prezzo.prezzoPartenza) &&
                        !noNullNoUndefinedNoEmptyString(values.prezzo.codDivisaPrezzo))
                        errors['prezzo.codDivisaPrezzo'] = true;

                    if(noNullNoUndefinedNoEmptyString(values.prezzo.codDivisaPrezzo) &&
                        !noNullNoUndefinedNoEmptyString(values.prezzo.prezzoPartenza))
                        errors['prezzo.prezzoPartenza'] = true;

                    //controlli aggiuntivi sul catalogoCommerciale
                    if(values.catalogoCommerciale.filter((x:any) => x.flagAttivo === true && !noNullNoUndefinedNoEmptyString(x.dataInizio)).length > 0)
                        errors['catalogoCommercialeDataInizio'] = "Data inizio obbligatoria per nuova modalità di servizio";
                    
                    if(values.catalogoCommerciale.filter((x:any) => x.flagAttivo === true && 
                        noNullNoUndefinedNoEmptyString(x.dataInizio) && noNullNoUndefinedNoEmptyString(x.dataFine) &&
                        new Date(x.dataInizio) > new Date(x.dataFine)).length > 0)
                    errors['catalogoCommercialeDataFine'] = "Data inizio deve precedere la data fine";

                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

                    return errors;
                }}
            >
                {({ errors, setFieldValue, values, resetForm }) => (
                <Form>
                    <div className="text-center d-flex">
                        <div className="col-7">
                            <Field component={SelectField} id="tipoProdotto" options={getOptionsFromDomain(getElement(listaDomain, 'tipo_prodotto_censimento'))}
                                name="tipoProdotto" placeholder="Select..." className="border rounded small-select" isClearableFlag={false} 
                                onChange={(option: any) => {
                                    resetForm()
                                    setFieldValue("tipoProdotto", option?.value)
                                }}/>
                        </div>
                        <div className="col-1"></div>
                        <div className="col-4">
                            {
                                mappingCensimentoMap[values.tipoProdotto].flagProdottoPadre &&
                                <Field id="flagProdottoPadre" name="flagProdottoPadre" component={SelectBooleanField} options={getOptionsFromDomain(optionsCheckboxFlagProdottoPadre)} 
                                    className="border rounded small-select" isClearable={false} handleChange={(e:any) => handleOnChangeFlagProdottoPadre(e, values, setFieldValue)}/>
                            }
                        </div>
                    </div>

                    <div className="row text-center">
                        <div className="intestazione"> Informazioni generali </div>
                        {renderFields(errors, values, 'campiGenerici')}
                    </div>

                    <div className="row text-center">
                        <div className="intestazione"> Informazioni aggiuntive </div>
                        {renderFields(errors, values, 'campiAggiuntivi')}
                    </div>

                    <div className="row text-center mb-5">
                        <div className="intestazione"> Informazioni default <Button onClick={handleShowDefaultForms} className="mx-5">{showDefaultForms.text}</Button></div>
                            {   showDefaultForms.state ?
                                <>
                                    {renderFields(errors, values, 'campiDefault')}
                                    <div className="p-4 bg-light">
                                        <div className="d-flex flex-column flex-wrapper">
                                            <div id="title" className="d-flex flex-row justify-content-between p-2 cc-title">
                                                <div className="m-2">MODALITA SERVIZIO </div>
                                                <div className="d-flex justify-content-end">
                                                    <div className="mx-4">DATA INIZIO</div>
                                                    <div className="mx-5">DATA FINE</div>
                                                </div>
                                            </div>
                                        </div>
                                        {renderCatalogoCommerciale(values, errors)}
                                        {errors.catalogoCommercialeDataInizio && <p className="errors m-2">{errors?.catalogoCommercialeDataInizio! as string}</p>}
                                        {errors.catalogoCommercialeDataFine && <p className="errors m-2">{errors?.catalogoCommercialeDataFine! as string}</p>}
                                    </div>
                                </>
                                : <></>
                            }
                            
                        </div>
                    <Button variant="primary" className="btn-cc" type="submit">Censisci Prodotto</Button>
                </Form>
                )}

            </Formik>
        </div>
    )
}