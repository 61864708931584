import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/store";
import { getAPIDefaultListState } from "../../../shared/functions";
import { Status } from "../../../shared/interface";
import { ricercaLineeAPI } from "./RicercaLineeAPI";

const initialState = {
  listaLinee: getAPIDefaultListState(),
  listaLineeStatus: Status.idle
}

export const ricercaLinee = createAsyncThunk(
  'catalogoProdotti/ricercaLinee',
  async (filtri?: any) => {
    const response = await ricercaLineeAPI(filtri);
    return response.data;
  }
);

export const listaLineeSlice = createSlice({
  name: 'listaLinee',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ricercaLinee.pending, (state) => {
        state.listaLineeStatus = Status.start;
      })
      .addCase(ricercaLinee.fulfilled, (state, action) => {
        state.listaLineeStatus = Status.success;
        state.listaLinee = action.payload;
      })
      .addCase(ricercaLinee.rejected, (state) => {
        state.listaLineeStatus = Status.failed;
      })
  },
});

export const selectListaLinee = (state: RootState) => state.listaLineeStore.listaLinee.data;
export const countListaLinee = (state: RootState) => state.listaLineeStore.listaLinee.pagination.count;

export default listaLineeSlice.reducer;