import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import catalogoProdottiReducer from '../features/catalogo-prodotti/lista/ListaProdottiSlice';
import dettaglioProdottoReducer from '../features/catalogo-prodotti/dettaglio/DettaglioProdottoSlice';
import listaDomainReducer from '../features/catalogo-prodotti/domain/ListaDomainSlice';
import censimentoProdottoReducer from '../features/catalogo-prodotti/censimento/CensimentoProdottoSlice';
import listaLineeeReducer from '../features/catalogo-prodotti/censimentoLinee/ricercaLinee/RicercaLineeSlice';

export const store = configureStore({
  reducer: {
    listaProdottiStore: catalogoProdottiReducer,
    dettaglioProdottoStore: dettaglioProdottoReducer,
    listaDomainStore: listaDomainReducer,
    censimentoProdottoStore: censimentoProdottoReducer,
    listaLineeStore: listaLineeeReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
