import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { Status, dominioList } from "../../shared/interface";
import { recuperaListaDomain } from "./ListaDomainAPI";

const initialState = {
  listaDomain: [] as dominioList[],
  listaDomainStatus: Status.idle
}

export const getListaDomain = createAsyncThunk(
  'catalogoProdotti/dominiCatalogoProdotti',
  async () => {
    const response = await recuperaListaDomain();
    return response.data.data.domini;
  }
);

export const listaDomainSlice = createSlice({
  name: 'listaProdotti',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListaDomain.pending, (state) => {
        state.listaDomainStatus = Status.start;
      })
      .addCase(getListaDomain.fulfilled, (state, action) => {
        state.listaDomainStatus = Status.success;
        state.listaDomain = action.payload;
      })
      .addCase(getListaDomain.rejected, (state) => {
        state.listaDomainStatus = Status.failed;
      })
  },
});

export const selectListaDomain = (state: RootState) => state.listaDomainStore.listaDomain;

export default listaDomainSlice.reducer;