import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../shared/interface";
import { censimentoLinea } from "./CensimentoLineeAPI";

const initialState = {
    censimentoLineeStatus : Status.idle
}

export const censimentoLinee = createAsyncThunk(
    'catalogoProdotti/CensimentoLinee',
    async (body: any) => {
      const response = await censimentoLinea(body);
      return response.data;
    }
);

export const censimentoLineeSlice = createSlice({
    name: 'censimentoLinee',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(censimentoLinee.pending, (state) => {
          state.censimentoLineeStatus = Status.start;
        })
        .addCase(censimentoLinee.fulfilled, (state, action) => {
            state.censimentoLineeStatus = Status.success;
        })
        .addCase(censimentoLinee.rejected, (state) => {
        state.censimentoLineeStatus = Status.failed;
        })
    },
});