import React from 'react'
import {createFilter} from 'react-select'
import Select from 'react-select'

export const SelectField = ({ placeholder, options, field, form, disabled, className, onChange, isClearableFlag }: any) => (
  <Select
    key={`key_${field.value}`}
    options={options}
    name={field.name}
    placeholder={placeholder}
    filterOption={createFilter({ignoreAccents: false})}
    value={options ? options.find((option: any) => option.value === field.value) : ''}
    onChange={onChange !== undefined ? (option: any) => onChange(option) : (option: any) => form.setFieldValue(field.name, option?.value)}
    className={className}

    isDisabled={disabled}
    isClearable={isClearableFlag !== null && isClearableFlag !== undefined ? isClearableFlag : true}
  />
)