import axios from 'axios';
import React,{ useEffect, useState } from 'react';
import { Toast, ToastContainer, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import { REACT_APP_BASE_URL } from '../../app/conf';
console.log(REACT_APP_BASE_URL)
axios.defaults.baseURL = REACT_APP_BASE_URL;

export function AxiosInterceptor({ children }: any) {
  const [showToast, setShowToast] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [message, setMessage] = useState({} as any);
  const handleErrorMessage = (text:String) => setMessage({ title: 'Errore', description: text, css: 'text-danger'} );
  const handleOkMessage = (text:String) => setMessage({ title: 'Ok', description: text, css: 'text-success'} );
  const hideToast = () => setShowToast(false);
  const navigate = useNavigate();

  //request
  useEffect(() => {
    const reqInterceptor = (request: any) => {
      setShowSpinner(true);
      return request;
    }
    const errInterceptor = (error: any) => {
      handleErrorMessage(error.message);
      setShowSpinner(false);
      setShowToast(true);
      return Promise.reject(error);
    }
    const interceptor = axios.interceptors.request.use(reqInterceptor, errInterceptor);
    return () => axios.interceptors.request.eject(interceptor);
  }, [navigate]);

  //response
  useEffect(() => {
    const resInterceptor = (response: any) => {
      if((response.config.url.includes('/censimentoProdotto') || response.config.url.includes('/censimentoLinea') ) && 
            response.request.status === 200){
        handleOkMessage('Censimento andato a buon fine');
        setShowToast(true);
      }

      setShowSpinner(false);
      return response;
    }
    const errInterceptor = (error: any) => {
      setShowSpinner(false);
      handleErrorMessage(error.response.data ? error.response.data : error.message);
      setShowToast(true);
      if (error.response.status === 401) {
        navigate('/login');
      }
      return Promise.reject(error);
    }
    const interceptor = axios.interceptors.response.use(resInterceptor, errInterceptor);
    return () => axios.interceptors.response.eject(interceptor);
  }, [navigate]);

  return (
      <>
        {showSpinner &&
            <div className="spinner-container">
              <Spinner animation="border" role="status" variant="primary">
                <span className="visually-hidden">Caricamento...</span>
              </Spinner>
            </div>
        }
        <ToastContainer position="top-end">
          <Toast onClose={hideToast} autohide delay={5000} show={showToast}>
            <Toast.Header>
              <strong className={`me-auto ${message.css}`}>{message.title}</strong>
            </Toast.Header>
            <Toast.Body>{message.description}</Toast.Body>
          </Toast>
        </ToastContainer>
        {children}
      </>
  );
}